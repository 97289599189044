export const STATUS = [
  {
    label: "Select Status",
    value: "",
  },
  {
    label: "Pending",
    value: "PENDING",
  },
  {
    label: "Declined",
    value: "DECLINED",
  },
  {
    label: "Approved",
    value: "APPROVED",
  },
];
export const TRANSACTIONTYPE = [
  {
    label: "Select Transaction Type",
    value: "",
  },
  {
    label: "Deposit",
    value: "DEPOSIT",
  },
  {
    label: "Withdraw",
    value: "WITHDRAW",
  },
];

export const QUESTIONSTYPE = [
  {
    label: "Text Based Question",
    value: "TEXT_BASED",
  },
  {
    label: "MCQ",
    value: "MCQ",
  },
];
