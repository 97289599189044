import { useQuery } from "@tanstack/react-query";
import { GetStudentCourses } from "../../../../API/Students/SingleSutdent/GetStudentCourses";
import { Dropdown, ProgressBar, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../../../constant/theme";
import { IoIosStar } from "react-icons/io";

function Courses({ id, title, enrollDate }) {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const navigate = useNavigate();

  const theadData = [
    { heading: "ID." },
    { heading: "Title" },
    { heading: "Progress" },
    { heading: "Rate" },
    { heading: "Enroll Date" },
    { heading: "Action" },
  ];

  const {
    data: CoursesData,
    isLoading: CoursesLoading,
    isError: CoursesError,
    error,
  } = useQuery({
    queryKey: ["SingleStudentCourses", id, title],
    queryFn: () => GetStudentCourses(id, title),
  });

  useEffect(() => {
    if (CoursesData) {
      const filtered = CoursesData.filter((course) =>
        enrollDate ? new Date(course.enrollDate) >= new Date(enrollDate) : true
      );
      setFilteredCourses(filtered);
      setPageNo(0); // Reset to the first page whenever date filter changes
    }
  }, [CoursesData, enrollDate]);

  const totalPages = Math.ceil(filteredCourses.length / itemsPerPage);

  const paginatedCourses = filteredCourses.slice(
    pageNo * itemsPerPage,
    (pageNo + 1) * itemsPerPage
  );

  if (
    CoursesError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        {" "}
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }
  if (CoursesLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  return filteredCourses.length === 0 ? (
    <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
      <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
        No data found
      </div>
    </div>
  ) : (
    <div className="card-body">
      <div className="table-responsive">
        <div
          id="holidayList"
          className="dataTables_wrapper no-footer">
          <div className="justify-content-between d-sm-flex">
            <div className="dataTables_length">
              <label className="d-flex align-items-center">
                Show
                <Dropdown className="search-drop">
                  <Dropdown.Toggle
                    as="div"
                    className="search-drop-btn">
                    {itemsPerPage}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setItemsPerPage(10)}>
                      10
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(20)}>
                      20
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(30)}>
                      30
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                entries
              </label>
            </div>
          </div>
          <table
            id="example4"
            className="display dataTable no-footer w-100">
            <thead>
              <tr>
                {theadData.map((item, ind) => (
                  <th key={ind}>{item.heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedCourses.map((data, ind) => (
                <tr
                  key={ind}
                  onClick={() => navigate("/all-courses/" + data.id)}>
                  <td>
                    <strong>{data.id}</strong>
                  </td>
                  <td>
                    <img
                      className="rounded-circle me-2"
                      width="35"
                      src={data.image}
                      alt="Person"
                      onError={(e) => {
                        e.target.src = IMAGES.Avatpng1;
                      }}
                    />
                    {data.title}
                  </td>
                  <td className="d-flex align-items-center gap-2">
                    <p>{data.progress}%</p>
                    <ProgressBar
                      now={data.progress}
                      variant="primary"
                      className="mb-3 w-100"
                    />
                  </td>
                  <td className="gap-2">
                    <IoIosStar
                      size={22}
                      fill="#FFD600"
                    />
                    <strong className="ms-1">{data.rate}</strong>
                  </td>
                  <td>{new Date(data.enrollDate).toDateString()}</td>
                  <td className="d-flex justify-content-between align-items-center">
                    <Link
                      to={"/all-courses/" + data.id}
                      className="btn sharp btn-primary d-flex justify-content-center align-items-center gap-2 w-100">
                      <span>Open</span>
                      <i className="fa fa-arrow-right" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              Showing {pageNo * itemsPerPage + 1} to{" "}
              {filteredCourses.length > (pageNo + 1) * itemsPerPage
                ? (pageNo + 1) * itemsPerPage
                : filteredCourses.length}{" "}
              of {filteredCourses.length} entries
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate">
              <Link
                className="paginate_button previous disabled"
                to="#"
                onClick={() => {
                  if (pageNo > 0) setPageNo(pageNo - 1);
                }}>
                Previous
              </Link>
              <span>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (number, i) => (
                    <Link
                      key={i}
                      to="#"
                      className={`paginate_button ${
                        pageNo === i ? "current" : ""
                      }`}
                      onClick={() => setPageNo(i)}>
                      {number}
                    </Link>
                  )
                )}
              </span>
              <Link
                className="paginate_button next"
                to="#"
                onClick={() => {
                  if (pageNo < totalPages - 1) setPageNo(pageNo + 1);
                }}>
                Next
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
