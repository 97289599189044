import { useMutation } from "@tanstack/react-query";
import React, { Fragment, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaFileLines, FaFileVideo } from "react-icons/fa6";
import { RxDragHandleDots2 } from "react-icons/rx";
import { DeleteCourseLessonsFile } from "../../../../../API/Courses/SingleCourse/CourseLessons/DeleteCourseLessonsFile";
import { useParams } from "react-router-dom";
import { queryClient } from "../../../../../App";
import Swal from "sweetalert2";
import { Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { EditCourseLessonsFileTitle } from "../../../../../API/Courses/SingleCourse/CourseLessons/EditCourseLessonsFileTitle";
import { EditCourseLessonsFileNo } from "../../../../../API/Courses/SingleCourse/CourseLessons/EditCourseLessonsFileNo";
import { COURSEFILES } from "../../../../../util/UploadToS3";
import {
  deleteFileDigitalOcean,
  generateSignedUrl,
} from "../../../../../util/accessDigitalOcean";
import { TokenDecode } from "../../../../../util/TokenDecode";

const loginSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Your title must consist of at least 3 characters")
    .max(50, "Your title must consist of no more than 50 characters")
    .matches(
      /^(?=.*[a-zA-Z\u0621-\u064A])[a-zA-Z\u0621-\u064A0-9 _-]*$/,
      "Title must contain letters and can include numbers, underscores, dashes, and spaces, but cannot contain special characters like @#$%"
    )
    .notOneOf([/^\d+$/], "Title cannot consist of numbers only"),
});

function FilesList({ lesson, setFiles }) {
  const [editModalOpen, setEditModalOpen] = useState({});
  const [videoOpen, setVideoOpen] = useState({});
  const [currentVideoSrc, setCurrentVideoSrc] = useState("");
  const { id } = useParams();

  const { mutate: ChangeLessonNo } = useMutation({
    mutationFn: ({ courseId, lessonId, fileId, fileNo }) => {
      EditCourseLessonsFileNo(courseId, lessonId, fileId, fileNo);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["CourseLessons"]);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });

  const { mutate: editFileTitle } = useMutation({
    mutationFn: ({ courseId, lessonId, fileId, title }) =>
      EditCourseLessonsFileTitle(courseId, lessonId, fileId, title),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["CourseLessons"] });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "File title updated successfully",
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });

  const { mutate: DeleteCourseLessonFile } = useMutation({
    mutationFn: ({ courseId, lessonId, fileId }) => {
      return DeleteCourseLessonsFile(courseId, lessonId, fileId);
    },
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries({ queryKey: ["CourseLessons"] });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });

  function deleteLessonFile(lessonId, fileId, filePath, file) {
    console.log(filePath, "fwfouhiewgjerwoigjherw");
    Swal.fire({
      title: "Are you sure to you want to delete this lesson ?",
      text: "You will not be able to recover this imaginary file !!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteCourseLessonFile({ courseId: id, lessonId, fileId });
        deleteFileDigitalOcean(filePath, file);
        Swal.fire(
          "Deleted!",
          `Lesson file has been deleted successfully.`,
          "success"
        );
      }
    });
  }
  // Handler when drag ends to reorder files
  const onDragEnd = (result) => {
    if (!result.destination) return; // If dropped outside the list, do nothing

    const newFiles = Array.from(lesson.files);
    const [reorderedItem] = newFiles.splice(result.source.index, 1);
    newFiles.splice(result.destination.index, 0, reorderedItem);

    setFiles(lesson.id, newFiles); // Update local state to reflect the new order

    // Example: Updating the backend with the new file number
    const newFileNo = result.destination.index + 1; // New fileNo based on its position
    if (reorderedItem.fileNo !== newFileNo) {
      ChangeLessonNo({
        courseId: id,
        lessonId: lesson.id,
        fileId: reorderedItem.id,
        fileNo: { fileNo: newFileNo }, // Send the new fileNo
      });
      reorderedItem.fileNo = newFileNo; // Update local state to match the new file number
    }
  };

  const communityId = TokenDecode(localStorage.getItem("token"))[
    "community-id"
  ];

  const handleOpenEditModal = (fileId) => {
    setEditModalOpen((current) => ({ ...current, [fileId]: true }));
  };

  const handleCloseEditModal = (fileId) => {
    setEditModalOpen((current) => ({ ...current, [fileId]: false }));
  };
  const handleOpenVideoModal = async (file) => {
    const url = await generateSignedUrl(file.filePath, communityId);
    setVideoOpen((current) => ({ ...current, [file.id]: true }));
    setCurrentVideoSrc(url); // Assuming `file.filePath` is correct and leads to the video source URL.
    console.log(url);
  };

  const handleCloseVideoModal = (fileId) => {
    setVideoOpen((current) => ({ ...current, [fileId]: false }));
    setCurrentVideoSrc("");
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`droppable-${lesson.id}`}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="files-list">
            {!lesson || lesson.files.length === 0 ? (
              <p>No files available for this lesson !</p>
            ) : (
              lesson.files.map((file, index) => (
                <>
                  <Draggable
                    key={file.id} // Ensure keys are unique and use `id` instead of `fileNo` if possible
                    draggableId={`draggable-${file.id}`} // Unique draggableId across all items
                    index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="my-2 d-flex justify-content-between align-items-center">
                        <div className="d-flex gap-3">
                          <div {...provided.dragHandleProps}>
                            <RxDragHandleDots2
                              size={28}
                              color="#1367c8"
                            />
                          </div>
                          <strong
                            className="text-monospace"
                            style={{
                              minWidth: "50px",
                              display: "inline-block",
                            }}>
                            #{file.id}
                          </strong>
                          {file.fileType === "VIDEO" ? (
                            <div
                              onClick={() => handleOpenVideoModal(file)}
                              className="d-flex gap-2 cursor-pointer">
                              <FaFileVideo
                                size={20}
                                color="#1367c8"
                              />
                              <p>{file.title}</p>
                            </div>
                          ) : (
                            <a
                              className="d-flex gap-2 cursor-pointer"
                              href={`${COURSEFILES}${file.filePath}`}>
                              <FaFileLines
                                size={20}
                                color="#1367c8"
                              />
                              {file.title}
                            </a>
                          )}
                        </div>

                        <Modal
                          show={!!videoOpen[file.id]}
                          onHide={() => handleCloseVideoModal(file.id)}
                          size="xl"
                          centered>
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Video Playback {file.title}
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <video
                              width="100%"
                              controls
                              autoplay>
                              <source
                                src={currentVideoSrc}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="primary"
                              onClick={() => handleCloseVideoModal(file.id)}>
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        <div className="d-flex gap-4">
                          <button
                            onClick={() => handleOpenEditModal(file.id)}
                            className="btn sharp btn-primary">
                            <i className="fa fa-pencil" />
                          </button>
                          <button
                            onClick={() =>
                              deleteLessonFile(
                                lesson.id,
                                file.id,
                                file.filePath,
                                file.fileType,
                                file
                              )
                            }
                            className="btn sharp btn-danger">
                            <i className="fa fa-trash" />
                          </button>
                          <Modal
                            className="fade bd-example-modal-lg modal-dialog-centered"
                            show={!!editModalOpen[file.id]}
                            onHide={() => handleCloseEditModal(file.id)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <Modal.Header>
                              <Modal.Title>
                                Edit File Title #{file.id}
                              </Modal.Title>
                              <Button
                                variant=""
                                className="btn-close"
                                onClick={() =>
                                  handleCloseEditModal(file.id)
                                }></Button>
                            </Modal.Header>
                            <Modal.Body>
                              <Formik
                                initialValues={{
                                  title: "",
                                }}
                                validationSchema={loginSchema}
                                // validateOnBlur={true}
                                // validateOnChange={true}
                                onSubmit={(values, actions) => {
                                  editFileTitle(
                                    {
                                      courseId: id,
                                      lessonId: lesson.id,
                                      fileId: file.id,
                                      title: values.title, // Ensure this is the name of your Formik field
                                    },
                                    {
                                      onSuccess: () => {
                                        actions.resetForm();
                                        actions.setSubmitting(false);
                                        handleCloseEditModal(file.id);
                                      },
                                      onError: () => {
                                        actions.setSubmitting(false);
                                      },
                                    }
                                  );
                                }}>
                                {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                }) => (
                                  <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="title">
                                              Title
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id="title"
                                              placeholder="Enter Title"
                                              type="text"
                                              name="title"
                                              className={`form-control ${
                                                touched.title
                                                  ? errors.title
                                                    ? "is-invalid"
                                                    : "is-valid"
                                                  : ""
                                              }`}
                                              value={values.title}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            {touched.title && errors.title && (
                                              <div
                                                id="val-username1-error"
                                                className="invalid-feedback animated fadeInUp">
                                                {errors.title}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <Modal.Footer>
                                        <Button
                                          variant="danger light"
                                          onClick={() =>
                                            handleCloseEditModal(file.id)
                                          }>
                                          Close
                                        </Button>
                                        <Button
                                          type="submit"
                                          variant="primary">
                                          Save changes
                                        </Button>
                                      </Modal.Footer>
                                    </form>
                                  </div>
                                )}
                              </Formik>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    )}
                  </Draggable>
                </>
              ))
            )}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default FilesList;
