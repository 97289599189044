import { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DatePicker } from "rsuite";

function FilterCourses({ activeTab, filters, onFilterChange }) {
  const [open, setOpen] = useState(true);
  const [search, setSearch] = useState(filters?.search || "");
  const [date, setDate] = useState(filters?.date || "");

  useEffect(() => {
    onFilterChange({ search, date });
  }, []);

  useEffect(() => {
    setSearch(filters?.search || "");
    setDate(filters?.date || "");
  }, [activeTab, filters]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleDateChange = (value) => {
    const formattedDate = value
      ? new Date(value).toISOString().slice(0, 10)
      : "";
    setDate(formattedDate);
  };

  const resetFilters = () => {
    setSearch("");
    setDate("");
    onFilterChange({ search: "", date: "" });
  };
  const PlaceHolder =
    (activeTab === "Students" && "Search by Student Name or ID") ||
    (activeTab === "Lessons" && "Search by Lesson ID or Title") ||
    (activeTab === "Assignments" && "Search by Exam Assignment Title or Id") ||
    (activeTab === "Exams" && "Search by Exam Title") ||
    (activeTab === "Submissions" && "Search by Submission ID") ||
    (activeTab === "Appeal" && "Search by Appeal ID or Exam Title") ||
    (activeTab === "Appeals" && "Search by Appeal ID or Student Name") ||
    (activeTab === "Rates" && "Search by Exam Id or Student ID");
  const DateLabel =
    (activeTab === "Students" && "Filter by Student Enroll Date") ||
    (activeTab === "Lessons" && "Filter by Open Date") ||
    (activeTab === "Assignments" && "Filter by Assignment openDate") ||
    (activeTab === "Submissions" && "Filter by Submission Date") ||
    (activeTab === "Appeals" && "Filter by Appeal Date") ||
    (activeTab === "Exams" && "Filter by Start Date") ||
    (activeTab === "Rates" && "Filter by Rate Date");

  return (
    activeTab !== "Overview" &&
    activeTab !== "Questions" && (
      <div className="filter cm-content-box box-primary">
        <div
          className="content-title"
          onClick={() => setOpen(!open)}>
          <div className="cpa">
            <i className="fas fa-filter me-2"></i>Filter
          </div>
          <div className="tools">
            <Link
              to="#"
              className={`SlideToolHeader ${open ? "collapse" : "expand"}`}>
              <i className="fas fa-angle-up"></i>
            </Link>
          </div>
        </div>

        <Collapse in={open}>
          <div className="cm-content-body form excerpt">
            <div className="card-body">
              <div className="row filter-row">
                <div className="col-xl-3 col-sm-6">
                  <label className="form-label">Search</label>
                  <input
                    onChange={handleSearchChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onFilterChange({ search, date });
                      }
                    }}
                    value={search}
                    type="text"
                    className="form-control mb-xl-0 mb-3"
                    placeholder={PlaceHolder}
                  />
                </div>

                <div className="col-xl-3 col-sm-6 d-flex flex-column ">
                  <label className="form-label">Register Date</label>
                  <DatePicker
                    placeholder={DateLabel}
                    className="picker-suit"
                    onChange={handleDateChange}
                    value={date ? new Date(date) : null}
                    format="yyyy-MM-dd"
                  />
                </div>

                <div className="col-xl-3 col-sm-6 align-self-end mt-4">
                  <button
                    onClick={() => onFilterChange({ search, date })}
                    className="btn btn-primary rounded-sm me-2"
                    title="Click here to Search"
                    type="button">
                    <i className="fa fa-search me-1" /> Apply
                  </button>
                  <button
                    onClick={resetFilters}
                    className="btn btn-danger rounded-sm light"
                    title="Click here to remove filter"
                    type="button">
                    Remove Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    )
  );
}

export default FilterCourses;
