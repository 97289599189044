import React from "react";
import { DatePicker } from "rsuite";
import * as Yup from "yup";
import { Formik } from "formik";
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../../layouts/PageTitle";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { queryClient } from "../../../../../App";
import { AddNewAssignment } from "../../../../../API/Courses/SingleCourse/CourseAssignments/AddNewAssignment";

const lessonSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .min(3, "Your title must consist of at least 3 characters")
    .matches(
      /^(?=.*[a-zA-Z\u0600-\u06FF])[a-zA-Z\u0600-\u06FF0-9 _-]*$/,
      "Title must contain letters and can include numbers, underscores, dashes, and spaces, but cannot contain special characters like @#$%"
    )
    .notOneOf([/^\d+$/], "Title cannot consist of numbers only"),
  openDate: Yup.date()
    .nullable()
    .notRequired()
    .typeError("Invalid date format")
    .test("is-future-date", "The date must be in the future", (value) => {
      return !value || value > new Date(); // Checks if the date is in the future
    }),
  deadline: Yup.date()
    .required("Deadline is required")
    .typeError("Invalid date format")
    .test("is-future-date", "The date must be in the future", (value) => {
      return !value || value > new Date(); // Checks if the date is in the future
    })
    .test(
      "is-after-openDate",
      "Deadline must be after the open time",
      function (value) {
        const { openDate } = this.parent;
        return !openDate || !value || value > openDate;
      }
    ),
  description: Yup.string()
    .min(3, "Description must consist of at least 3 characters")
    .required("Please enter a description"),
});

const AddAssignment = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { mutate } = useMutation({
    mutationFn: (data) => AddNewAssignment(id, data),
    onSuccess: () => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Course Lesson Added Successfully",
      });
      queryClient.invalidateQueries({ queryKey: ["CourseAssignments"] });
      navigate(`/all-courses/${id}/Assignments`);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });
  return (
    <>
      <PageTitle
        title={"Coures Assignments"}
        activeMenu={"All Courses"}
        motherMenu={"Courses"}
        userId={id}
        secondMenu={"Add Lesson"}
        goBack={true}
        goTo={`/all-courses/${id}/Assignments`}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Assignment Details</h4>
            </div>

            <Formik
              initialValues={{
                title: "",
                openDate: null,
                deadline: null,
                description: "",
              }}
              validationSchema={lessonSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                console.log(values);
                mutate(values, {
                  onSuccess: () => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                  },
                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="title">
                            Title
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="title"
                            placeholder="Enter Coures Title"
                            type="text"
                            name="title"
                            className={`form-control ${
                              touched.title
                                ? errors.title
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.title && errors.title && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.title}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="openDate">
                            Open Date
                          </label>
                          <DatePicker
                            id="openDate"
                            placeholder="Select Open Time"
                            format="yyyy-MM-dd HH:mm:ss" // You can adjust the format as needed
                            style={{
                              width: "100%",
                              borderColor:
                                touched.openDate && errors.openDate
                                  ? "red"
                                  : "initial",
                              boxShadow: "none",
                            }}
                            value={values.openDate}
                            onChange={(value) =>
                              setFieldValue("openDate", value)
                            }
                            block // This prop makes the DatePicker take the full width of its parent
                          />
                          {touched.openDate && errors.openDate && (
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}>
                              {errors.openDate}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="deadline">
                            Deadline
                          </label>
                          <DatePicker
                            id="deadline"
                            placeholder="Select Open Time"
                            format="yyyy-MM-dd HH:mm:ss" // You can adjust the format as needed
                            style={{
                              width: "100%",
                              borderColor:
                                touched.deadline && errors.deadline
                                  ? "red"
                                  : "initial",
                              boxShadow: "none",
                            }}
                            value={values.deadline}
                            onChange={(value) =>
                              setFieldValue("deadline", value)
                            }
                            block // This prop makes the DatePicker take the full width of its parent
                          />
                          {touched.deadline && errors.deadline && (
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}>
                              {errors.deadline}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="description">
                            Description <span className="text-danger">*</span>
                          </label>
                          <input
                            id="description"
                            placeholder="Enter Description"
                            type="text"
                            name="description"
                            className={`form-control ${
                              touched.description && errors.description
                                ? "is-invalid"
                                : touched.description
                                ? "is-valid"
                                : ""
                            }`}
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.description && errors.description && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.description}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary me-1">
                          Submit
                        </button>
                        <button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-danger light">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAssignment;
