import { useQuery } from "@tanstack/react-query";
import { GetSingleExam } from "../../../../../../API/Courses/SingleCourse/CourseExams/GetSingleExam";
import { Row } from "react-bootstrap";

function ExamOverview({ id, examId }) {
  const {
    data: ExamOverView,
    isLoading: ExamOverViewLoading,
    isError: ExamOverViewError,
    error,
  } = useQuery({
    queryKey: ["ExamOverView", id, examId],
    queryFn: () => GetSingleExam(id, examId),
  });

  if (ExamOverViewLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  if (
    ExamOverViewError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }

  return (
    <div className="col-xl-12 mt-4 bg-body rounded p-4">
      <div className="profile-personal-info">
        <h3 className="text-dark  mb-4 border-bottom pb-2 border-dark">
          About Exam #{ExamOverView.examId}
        </h3>

        <div className="row mb-4">
          <div className="col-lg-3 col-md-4 col-sm-6 col-6">
            <h5 className="f-w-500">
              ID
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
            <span>{ExamOverView.examId}</span>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-3 col-md-4 col-sm-6 col-6">
            <h5 className="f-w-500">
              Title
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
            <span>{ExamOverView.title}</span>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-3 col-md-4 col-sm-6 col-6">
            <h5 className="f-w-500">
              Description
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
            <span>{ExamOverView.description}</span>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-3 col-md-4 col-sm-6 col-6">
            <h5 className="f-w-500">
              Duration
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
            <span>{ExamOverView.duration * 60} Minutes</span>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-3 col-md-4 col-sm-6 col-6">
            <h5 className="f-w-500">
              Start Date
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
            <span>{new Date(ExamOverView.startDate).toDateString()}</span>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-3 col-md-4 col-sm-6 col-6">
            <h5 className="f-w-500">
              Deadline
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
            <span>{new Date(ExamOverView.deadline).toDateString()}</span>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-3 col-md-4 col-sm-6 col-6">
            <h5 className="f-w-500">
              Attempts
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-lg-9 col-md-8 col-sm-6 col-6 text-muted">
            <span>
              {ExamOverView.oneTimeAccess ? "One Time" : "Multiple Times"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamOverview;
