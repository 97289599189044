import axiosInstance from "../../../../services/AxiosInstance";

export async function AddCourseStudent(courseId, email) {
  let url = `teacher-api/courses/${courseId}/students`;
  return axiosInstance
    .post(url, email)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error adding  course student:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
