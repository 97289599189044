import { useMutation, useQuery } from "@tanstack/react-query";
import { GetCourseStudents } from "../../../../../API/Courses/SingleCourse/CourseStudnts/GetCourseStudents";
import { Link, useParams } from "react-router-dom";
import { Dropdown, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { MdOutlineAutorenew } from "react-icons/md";
import { IMAGES } from "../../../../constant/theme";
import { isPast, parseISO } from "date-fns";
import { queryClient } from "../../../../../App";
import { RenewCourseSubscription } from "../../../../../API/Courses/SingleCourse/CourseStudnts/RenewCourseSubscription";
import Swal from "sweetalert2";
import { DeleteCourseStudent } from "../../../../../API/Courses/SingleCourse/CourseStudnts/DeleteCourseStudent";
import { PROFILEIMG } from "../../../../../util/UploadToS3";
const theadData = [
  { heading: "ID." },
  { heading: "Name" },
  { heading: "Enroll Date" },
  { heading: "Email" },
  { heading: "Expire Date" },
  { heading: "Action" },
];

function CourseStudents({ monthly, search, enrollDate }) {
  const [sort, setSortata] = useState(10);
  const { id } = useParams();
  const activePag = useRef(0);
  const [pageNo, setPageNo] = useState(0);
  const [filteredCourses, setFilteredCourses] = useState([]);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["CourseStudents", id],
    queryFn: () => GetCourseStudents(id),
  });
  const { mutate } = useMutation({
    mutationFn: (studentId) => RenewCourseSubscription(id, studentId),
    onSuccess: () => {
      queryClient.invalidateQueries(["CourseStudents"]);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Course Renewed Successfully",
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });

  const { mutate: DeleteStudnetCousre } = useMutation({
    mutationFn: (studentId) => DeleteCourseStudent(id, studentId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["CourseStudents"] });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });

  function deleteStudent(id) {
    Swal.fire({
      title: "Are you sure to you want to delete this student ?",
      text: "You will not be able to recover this imaginary file !!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteStudnetCousre(id);
        Swal.fire(
          "Deleted!",
          `Student with id #${id} has been deleted successfully.`,
          "success"
        );
      }
    });
  }

  useEffect(() => {
    if (data) {
      const filtered = data.filter((student) => {
        const matchesTitle = search
          ? student.firstName.toLowerCase().includes(search.toLowerCase()) ||
            student.id.toString() === search
          : true;

        const matchesDate = enrollDate
          ? new Date(student.enrollDate).toISOString() >=
            new Date(enrollDate).toISOString()
          : true;
        return matchesTitle && matchesDate;
      });
      setFilteredCourses(filtered);
      setPageNo(0); // Reset to the first page whenever search term changes
    }
  }, [data, search, id, enrollDate]);

  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  if (
    isError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }

  let paggination =
    Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1) || [];

  return (
    <div className="card-body">
      <div className="table-responsive">
        <div
          id="holidayList"
          className="dataTables_wrapper no-footer">
          <div className="justify-content-between d-sm-flex">
            <div className="dataTables_length">
              <label className="d-flex align-items-center">
                Show
                <Dropdown className="search-drop">
                  <Dropdown.Toggle
                    as="div"
                    className="search-drop-btn">
                    {sort}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setSortata("10")}>
                      10
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSortata("20")}>
                      20
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSortata("30")}>
                      30
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                entries
              </label>
            </div>
          </div>
          <table
            id="example4"
            className="display dataTable no-footer w-100">
            <thead>
              <tr>
                {theadData.map((item, ind) => (
                  <th key={ind}>{item.heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {!data || data.length === 0 ? (
                <p className="pt-5">No data found</p>
              ) : (
                filteredCourses.map((data, ind) => (
                  <tr key={ind}>
                    <td>
                      <strong>{data.id}</strong>
                    </td>
                    <td>
                      <img
                        className="rounded-circle me-2"
                        width="35"
                        src={`${PROFILEIMG}${data.image}`}
                        alt="Person"
                        onError={(e) => {
                          e.target.src = IMAGES.Avatpng1;
                        }}
                      />
                      {data.firstName + " " + data.lastName}
                    </td>
                    <td>{new Date(data.enrollDate).toDateString()}</td>
                    <td>
                      <Link to={"#"}>
                        <strong>{data.email}</strong>
                      </Link>
                    </td>

                    <td>
                      {data.expireDate !== null
                        ? new Date(data.expireDate).toDateString()
                        : "Life Time"}
                    </td>
                    <td className="d-flex justify-content-between align-items-center  gap-3 w-">
                      {data.expireDate !== null && (
                        <button
                          onClick={() => mutate(data.id)}
                          disabled={!isPast(parseISO(data.expireDate))}
                          className="btn sharp btn-primary d-flex justify-content-center align-items-center gap-2 w-100">
                          <span className="text-nowrap">
                            Renew Subscription
                          </span>
                          <MdOutlineAutorenew size={24} />
                        </button>
                      )}

                      <button
                        onClick={() => deleteStudent(data.id)}
                        disabled={!isPast(parseISO(data.expireDate))}
                        className="btn sharp btn-danger d-flex justify-content-center align-items-center w-75">
                        <span className="text-nowrap pe-2">Delete</span>
                        <i className="fa fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              Showing {activePag.current * sort + 1} to{" "}
              {isError
                ? 0
                : data?.length > (activePag.current + 1) * sort
                ? (activePag.current + 1) * sort
                : data?.length}{" "}
              of {data?.length} entries
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate">
              <Link
                className="paginate_button previous disabled"
                to="#"
                onClick={() => {
                  if (pageNo > 0) setPageNo(pageNo - 1);
                }}>
                Previous
              </Link>
              <span>
                {paggination.map((number, i) => (
                  <Link
                    key={i}
                    to="#"
                    className={`paginate_button  ${
                      pageNo === i ? "current" : ""
                    } `}
                    onClick={() => setPageNo(i)}>
                    {number}
                  </Link>
                ))}
              </span>
              <Link
                className="paginate_button next"
                to="#"
                onClick={() => {
                  if (pageNo < data?.totalPages - 1) setPageNo(pageNo + 1);
                }}>
                Next
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseStudents;
