import React from "react";
import { DatePicker } from "rsuite";
import * as Yup from "yup";
import { Formik } from "formik";
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../../layouts/PageTitle";
import { useMutation } from "@tanstack/react-query";
import { IoMdInformationCircle } from "react-icons/io";
import Swal from "sweetalert2";
import { queryClient } from "../../../../../App";
import { AddCourseExam } from "../../../../../API/Courses/SingleCourse/CourseExams/AddCourseExam";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const lessonSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters")
    .matches(
      /^(?!\d+$)[a-zA-Z\u0600-\u06FF0-9_-\s]*$/,
      "Title must be alphanumeric and can include underscores, dashes, and spaces"
    ),
  description: Yup.string()
    .min(6, "description must consist of at least 6 characters ")
    .required("Please enter a description"),
  openTime: Yup.date()
    .nullable()
    .notRequired()
    .typeError("Invalid date format")
    .test("is-future-date", "The date must be in the future", (value) => {
      return !value || value > new Date(); // Checks if the date is in the future
    }),
  deadline: Yup.date()
    .notRequired()
    .nullable()
    .typeError("Invalid date format")
    .test("is-future-date", "The date must be in the future", (value) => {
      return !value || value > new Date(); // Checks if the date is in the future
    })
    .test(
      "is-after-start",
      "The deadline must be after the start time",
      function (value) {
        const { startDateTime } = this.parent;
        return (
          !startDateTime || !value || new Date(value) > new Date(startDateTime)
        );
      }
    ),
  duration: Yup.number("Duration must be a number")
    .min(10, "Duration must be at least 1 minute")
    .nullable(),
  isOneTimeAccess: Yup.boolean().required("Please select an option"),
});

const AddExam = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { mutate } = useMutation({
    mutationFn: (data) => AddCourseExam(id, data),
    onSuccess: () => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Course Lesson Added Successfully",
      });
      queryClient.invalidateQueries({ queryKey: ["CourseLessons"] });
      navigate(`/all-courses/${id}/Exams`);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });
  return (
    <>
      <PageTitle
        title={"Course Exams"}
        activeMenu={"All Courses"}
        motherMenu={"Courses"}
        userId={id}
        secondMenu={"Add Exam"}
        goBack={true}
        goTo={`/all-courses/${id}/Exams`}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Exam Details</h4>
            </div>

            <Formik
              initialValues={{
                title: "",
                description: "",
                duration: null,
                startDateTime: null,
                deadline: null,
                isOneTimeAccess: false,
              }}
              validationSchema={lessonSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                console.log(values);
                mutate(values, {
                  onSuccess: () => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                  },
                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="title">
                            Title
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="title"
                            placeholder="Enter Exam Title"
                            type="text"
                            name="title"
                            className={`form-control ${
                              touched.title
                                ? errors.title
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.title && errors.title && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.title}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="startDateTime">
                            Start Time
                          </label>
                          <DatePicker
                            id="startDateTime"
                            placeholder="Select Open Time"
                            format="yyyy-MM-dd HH:mm:ss" // You can adjust the format as needed
                            style={{
                              width: "100%",
                              borderColor:
                                touched.startDateTime && errors.startDateTime
                                  ? "red"
                                  : "initial",
                              boxShadow: "none",
                            }}
                            value={values.startDateTime}
                            onChange={(value) =>
                              setFieldValue("startDateTime", value)
                            }
                            block // This prop makes the DatePicker take the full width of its parent
                          />
                          {touched.startDateTime && errors.startDateTime && (
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}>
                              {errors.startDateTime}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="deadline">
                            Deadline
                          </label>
                          <DatePicker
                            id="deadline"
                            placeholder="Select Deadline Time"
                            format="yyyy-MM-dd HH:mm:ss" // You can adjust the format as needed
                            style={{
                              width: "100%",
                              borderColor:
                                touched.deadline && errors.deadline
                                  ? "red"
                                  : "initial",
                              boxShadow: "none",
                            }}
                            value={values.deadline}
                            onChange={(value) =>
                              setFieldValue("deadline", value)
                            }
                            block // This prop makes the DatePicker take the full width of its parent
                          />
                          {touched.deadline && errors.deadline && (
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}>
                              {errors.deadline}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="duration">
                            Duration
                          </label>
                          <input
                            id="duration"
                            placeholder="Enter Exam Duration"
                            type="text"
                            name="duration"
                            className={`form-control ${
                              touched.duration && errors.duration
                                ? "is-invalid"
                                : touched.duration
                                ? "is-valid"
                                : ""
                            }`}
                            value={values.duration}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.duration && errors.duration && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.duration}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="description">
                            Description <span className="text-danger">*</span>
                          </label>
                          <input
                            id="description"
                            placeholder="Enter Exam Description"
                            type="text"
                            name="description"
                            className={`form-control ${
                              touched.description && errors.description
                                ? "is-invalid"
                                : touched.description
                                ? "is-valid"
                                : ""
                            }`}
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.description && errors.description && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.description}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip
                                id="button-tooltip"
                                className="custom-tooltip">
                                <strong> Policy :</strong> exam can be taken
                                many times or only once.
                              </Tooltip>
                            }>
                            <label className="form-label">
                              Attempt{" "}
                              <IoMdInformationCircle
                                color="#FFAA16"
                                size={18}
                              />
                            </label>
                          </OverlayTrigger>
                          <div className="mb-3">
                            <div className="radio mb-1">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="isOneTimeAccess"
                                  className="form-check-input me-2"
                                  checked={!values.isOneTimeAccess}
                                  onChange={() =>
                                    setFieldValue("isOneTimeAccess", false)
                                  }
                                />
                                Multi-Access
                              </label>
                            </div>
                            <div className="radio">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="isOneTimeAccess"
                                  className="form-check-input me-2"
                                  checked={values.isOneTimeAccess}
                                  onChange={() =>
                                    setFieldValue("isOneTimeAccess", true)
                                  }
                                />
                                One-Time Access
                              </label>
                            </div>
                          </div>
                          {touched.isOneTimeAccess &&
                            errors.isOneTimeAccess && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.isOneTimeAccess}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary me-1">
                          Submit
                        </button>
                        <button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-danger light">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddExam;
