import { useQuery } from "@tanstack/react-query";
import { Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { COURSEIMG } from "../../../../../../util/UploadToS3";
import { IMAGES } from "../../../../../constant/theme";
import PageTitle from "../../../../../layouts/PageTitle";
import { GetExamSubmissionFeedback } from "../../../../../../API/Courses/SingleCourse/CourseExams/SingleExamSubmissions/GetExamSubmissionFeedback";

function ExamSubbmitionsFeedback() {
  const [pageNo, setPageNo] = useState(0);
  const itemsPerPage = 5;
  const { id, examId, submissionId } = useParams();

  const {
    data: ExamQuestionsFeedback,
    isLoading: ExamQuestionsFeedbackLoading,
    isError: ExamQuestionsFeedbackError,
    error,
  } = useQuery({
    queryKey: ["ExamQuestionsFeedback", id, examId, submissionId],
    queryFn: () => GetExamSubmissionFeedback(id, examId, submissionId),
  });

  if (ExamQuestionsFeedbackLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  if (
    ExamQuestionsFeedbackError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }

  const sortedExamQuestions = ExamQuestionsFeedback.answers.sort((a, b) =>
    a.questionNo > b.questionNo ? 1 : -1
  );

  const totalScore = sortedExamQuestions.reduce((total, item) => {
    return total + item.score;
  }, 0);
  const totalQuestions = sortedExamQuestions.length;
  let paggination =
    (!ExamQuestionsFeedbackLoading &&
      Array(Math.ceil(ExamQuestionsFeedback.answers.length / itemsPerPage))
        .fill()
        .map((_, i) => i + 1)) ||
    [];
  const totalPages = Math.ceil(sortedExamQuestions.length / itemsPerPage);
  const paginatedExams = sortedExamQuestions.slice(
    pageNo * itemsPerPage,
    (pageNo + 1) * itemsPerPage
  );

  return (
    <>
      <PageTitle
        activeMenu={"All Submissions"}
        motherMenu={"Courses"}
        firstMenu={"All Courses"}
        secondMenu={"Assignments"}
        secondId={submissionId}
        thirdMenu={"Submissions"}
        userId={id}
        goBack
        goTo={`/all-courses/${id}/Exams/${examId}/Submissions`}
      />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="dataTables_wrapper no-footer">
                <h3 className="col-xl-12 text-center mt-4 pb-4 border-bottom  rounded">
                  Total Questions : {totalQuestions} - Student Score :
                  <span
                    className={`text-${
                      ExamQuestionsFeedback.examScore / 2 >
                      ExamQuestionsFeedback.studentTotalMarks
                        ? "danger"
                        : "success"
                    }`}>
                    {" "}
                    {ExamQuestionsFeedback.studentTotalMarks} /{" "}
                    {ExamQuestionsFeedback.examScore}
                  </span>
                </h3>
                {paginatedExams.map((question) =>
                  question.questionType === "MCQ" ? (
                    <div
                      key={question.questionNo}
                      className="col-xl-12 mt-4  rounded p-1 py-3 border-bottom">
                      <div className="d-flex justify-content-between">
                        <div>
                          <div>
                            <h4 className="text-primary ">
                              Score : {question.score} / {totalScore}
                            </h4>
                            <p className="text-black fs-5">
                              No {question.questionNo} of {totalQuestions}
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="text-black fs-4">
                        Question : {question.questionText}
                      </p>

                      <div className="d-flex justify-content-between text-black fs-5">
                        <div>
                          <p>Answers :</p>
                          {question.choices.map((choice) => (
                            <div
                              key={choice.choiceId}
                              className="radio mb-1 ms-1">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name={"choices" + question.questionNo}
                                  className={"form-check-input me-2"}
                                  checked={choice.isChosen}
                                  readOnly
                                />
                                {choice.isChosen && choice.isCorrect ? (
                                  <span className="text-primary">
                                    {choice.choiceText} - Correct and Selected
                                  </span>
                                ) : choice.isCorrect ? (
                                  <span className="text-primary">
                                    {choice.choiceText} - Correct
                                  </span>
                                ) : choice.isChosen ? (
                                  <span className="text-danger">
                                    {choice.choiceText} - Selected
                                  </span>
                                ) : (
                                  <span>{choice.choiceText}</span>
                                )}
                              </label>
                            </div>
                          ))}
                        </div>
                        <img
                          className="question-img"
                          src={COURSEIMG + "Exams/" + question.image}
                          alt="QuestionImg"
                          onError={(e) => (e.target.src = IMAGES.course1)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      key={question.questionNo}
                      className="col-xl-12 mt-4  rounded p-1 py-3 border-bottom">
                      <div className="d-flex justify-content-between">
                        <div>
                          <div>
                            <h4 className="text-primary ">
                              Score : {question.score} / {totalScore}
                            </h4>
                            <p className="text-black fs-5">
                              No {question.questionNo} of {totalQuestions}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="text-black fs-4">
                          Question : {question.questionText}
                        </p>

                        <div className="d-flex flex-column text-black fs-5">
                          <div>
                            <img
                              className="question-img"
                              src={COURSEIMG + "Exams/" + question.image}
                              alt="QuestionImg"
                              onError={(e) => (e.target.src = IMAGES.course1)}
                            />
                          </div>
                          <div className="mt-3 text-success">
                            <strong>Correct Answer :</strong>{" "}
                            {question.modelAnswer}
                          </div>
                          <div
                            className={`mt-3 text-${
                              question.correct ? "success" : "danger"
                            }`}>
                            <strong>Student's Answer :</strong>{" "}
                            {question.studentAnswer}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}

                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    Showing {pageNo * itemsPerPage + 1} to{" "}
                    {ExamQuestionsFeedback.answers.length >
                    (pageNo + 1) * itemsPerPage
                      ? (pageNo + 1) * itemsPerPage
                      : ExamQuestionsFeedback.answers.length}{" "}
                    of {ExamQuestionsFeedback.answers.length} entries
                  </div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example5_paginate">
                    <Link
                      className="paginate_button previous disabled"
                      to="#"
                      onClick={() => {
                        if (pageNo > 0) setPageNo(pageNo - 1);
                      }}>
                      Previous
                    </Link>
                    <span>
                      {paggination.map((number, i) => (
                        <Link
                          key={i}
                          to="#"
                          className={`paginate_button ${
                            pageNo === i ? "current" : ""
                          }`}
                          onClick={() => setPageNo(i)}>
                          {number}
                        </Link>
                      ))}
                    </span>
                    <Link
                      className="paginate_button next"
                      to="#"
                      onClick={() => {
                        if (pageNo < totalPages - 1) setPageNo(pageNo + 1);
                      }}>
                      Next
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExamSubbmitionsFeedback;
