// import React, { Component } from "react";
// import { Col, Row, Card } from "react-bootstrap";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
// import Alert from "sweetalert2";

// class CalendarBlog extends Component {
//   state = {
//     calendarEvents: [
//       {
//         title: "Atlanta Monster",
//         start: new Date("2024-01-07 00:00"),
//         id: "99999991",
//       },
//       {
//         title: "My Favorite Movie",
//         start: new Date("2024-01-11 00:00"),
//         id: "99999992",
//       },
//       {
//         title: "Enggement Function",
//         start: new Date("2024-01-17 00:00"),
//         id: "99999993",
//       },
//       {
//         title: "Marrige Function",
//         start: new Date("2024-01-23 00:00"),
//         id: "99999994",
//       },
//       {
//         title: "Party With Friends",
//         start: new Date("2024-01-26 00:00"),
//         id: "99999995",
//       },
//       {
//         title: "Atlanta Monster",
//         start: new Date("2024-02-07 00:00"),
//         id: "99999991",
//       },
//       {
//         title: "My Favorite Movie",
//         start: new Date("2024-02-11 00:00"),
//         id: "99999992",
//       },
//       {
//         title: "Enggement Function",
//         start: new Date("2024-02-17 00:00"),
//         id: "99999993",
//       },
//       {
//         title: "Marrige Function",
//         start: new Date("2024-02-23 00:00"),
//         id: "99999994",
//       },
//       {
//         title: "Party With Friends",
//         start: new Date("2024-02-26 00:00"),
//         id: "99999995",
//       },
//     ],
//     events: [
//       { title: "New Theme Release", id: "1", style: "primary" },
//       { title: "My Event", id: "2", style: "warning" },
//       { title: "Meet Manager", id: "3", style: "danger" },
//       { title: "Create New Theme", id: "4", style: "info" },
//       { title: "Project Launch ", id: "5", style: "dark" },
//       { title: "Meeting", id: "6", style: "secondary" },
//     ],
//   };

//   componentDidMount() {
//     let draggableEl = document.getElementById("external-events");
//     new Draggable(draggableEl, {
//       itemSelector: ".fc-event",
//       eventData: function (eventEl) {
//         let title = eventEl.getAttribute("title");
//         let id = eventEl.getAttribute("data");
//         return {
//           title: title,
//           id: id,
//         };
//       },
//     });
//   }

//   eventClick = (eventClick) => {
//     Alert.fire({
//       title: eventClick.event.title,
//       html:
//         `<div className="table-responsive">
//                     <table className="table">
//                        <tbody>
//                           <tr >
//                              <td>Title</td>
//                              <td><strong>` +
//         eventClick.event.title +
//         `</strong></td>
//                           </tr>
//                           <tr >
//                              <td>Start Time</td>
//                              <td><strong>
//                              ` +
//         eventClick.event.start +
//         `
//                              </strong></td>
//                           </tr>
//                        </tbody>
//                     </table>
//                  </div>`,

//       showCancelButton: true,
//       confirmButtonColor: "#d33",
//       cancelButtonColor: "#3085d6",
//       confirmButtonText: "Remove Event",
//       cancelButtonText: "Close",
//     }).then((result) => {
//       if (result.value) {
//         eventClick.event.remove();
//         Alert.fire("Deleted!", "Your Event has been deleted.", "success");
//       }
//     });
//   };
//   render() {
//     return (
//       <>
//         <Row>
//           <Col xl={"12"}>
//             <Card>
//               <Card.Body>
//                 <div
//                   className="demo-app-calendar"
//                   id="mycalendartest">
//                   <FullCalendar
//                     defaultView="dayGridMonth"
//                     headerToolbar={{
//                       start: "prev,next today",
//                       center: "title",
//                       end: "dayGridMonth,timeGridWeek,timeGridDay",
//                     }}
//                     rerenderDelay={10}
//                     eventDurationEditable={false}
//                     editable={true}
//                     droppable={true}
//                     plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
//                     ref={this.calendarComponentRef}
//                     weekends={this.state.calendarWeekends}
//                     events={this.state.calendarEvents}
//                     eventDrop={this.drop}
//                     eventReceive={this.eventReceive}
//                     eventClick={this.eventClick}
//                   />
//                 </div>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col xl={"12"}>
//             <Card>
//               <Card.Body>
//                 <h4 className="card-intro-title mb-0">Calendar</h4>
//                 <div
//                   id="external-events"
//                   className="my-3">
//                   <p>Drag and drop your event or click in the calendar</p>
//                   {this.state.events.map((event) => (
//                     <div
//                       className={`fc-event external-event light btn-${event.style}`}
//                       data-class={`bg-${event.style}`}
//                       title={event.title}
//                       data={event.id}
//                       key={event.id}>
//                       <i className="fa fa-move" />
//                       <span>{event.title}</span>
//                     </div>
//                   ))}
//                 </div>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>
//       </>
//     );
//   }
// }

// export default CalendarBlog;

import React, { useEffect, useRef } from "react";
import { Col, Row, Card } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Alert from "sweetalert2";
import { useQuery } from "@tanstack/react-query";
import { GetAllEvents } from "../../../API/Events/GetAllEvents";

const CalendarBlog = () => {
  const calendarComponentRef = useRef(null);
  const externalEventsRef = useRef(null);

  const {
    data: calendarEvents,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["calendarEvents"],
    queryFn: GetAllEvents,
  });

  // const events = [
  //   { title: "New Theme Release", id: "1", style: "primary" },
  //   { title: "My Event", id: "2", style: "warning" },
  //   { title: "Meet Manager", id: "3", style: "danger" },
  //   { title: "Create New Theme", id: "4", style: "info" },
  //   { title: "Project Launch", id: "5", style: "dark" },
  //   { title: "Meeting", id: "6", style: "secondary" },
  // ];

  useEffect(() => {
    if (externalEventsRef.current) {
      new Draggable(externalEventsRef.current, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("title");
          let id = eventEl.getAttribute("data");
          return {
            title: title,
            id: id,
          };
        },
      });
    }
  }, []);

  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  if (isError) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center align-items-center gap-2">
          Something went wrong: {error.message}
        </div>
      </Row>
    );
  }

  // const eventClick = (eventClick) => {
  //   Alert.fire({
  //     title: eventClick.event.title,
  //     html: `
  //       <div className="table-responsive">
  //         <table className="table">
  //           <tbody>
  //             <tr>
  //               <td>Title</td>
  //               <td><strong>${eventClick.event.title}</strong></td>
  //             </tr>
  //             <tr>
  //               <td>Start Time</td>
  //               <td><strong>${eventClick.event.start}</strong></td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>`,
  //     showCancelButton: true,
  //     confirmButtonColor: "#d33",
  //     cancelButtonColor: "#3085d6",
  //     confirmButtonText: "Remove Event",
  //     cancelButtonText: "Close",
  //   }).then((result) => {
  //     if (result.value) {
  //       Alert.fire(
  //         "Feature Disabled",
  //         "Event removal is currently disabled.",
  //         "info"
  //       );
  //     }
  //   });
  // };

  const eventReceive = (eventReceive) => {
    Alert.fire(
      "Feature Disabled",
      "Event addition is currently disabled.",
      "info"
    );
    eventReceive.revert();
  };

  const eventDrop = (eventDrop) => {
    Alert.fire("Feature Disabled", "Event drop is currently disabled.", "info");
    eventDrop.revert();
  };

  return (
    <Row>
      <Col xl={"12"}>
        <Card>
          <Card.Body>
            <div
              className="demo-app-calendar"
              id="mycalendartest">
              <FullCalendar
                defaultView="dayGridMonth"
                headerToolbar={{
                  start: "prev,next today",
                  center: "title",
                  end: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                rerenderDelay={10}
                eventDurationEditable={false}
                //  editable={true}
                //  droppable={true}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                ref={calendarComponentRef}
                weekends={true}
                events={calendarEvents}
                //  eventClick={eventClick}
                eventReceive={eventReceive}
                eventDrop={eventDrop}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
      {/* <Col xl={"4"}>
        <Card>
          <Card.Body>
            <h4 className="card-intro-title mb-0">Calendar</h4>
            <div
              id="external-events"
              ref={externalEventsRef}
              className="my-3">
              <p>Drag and drop your event or click in the calendar</p>
              {events.map((event) => (
                <div
                  className={`fc-event external-event light btn-${event.style}`}
                  data-class={`bg-${event.style}`}
                  title={event.title}
                  data={event.id}
                  key={event.id}>
                  <i className="fa fa-move" />
                  <span>{event.title}</span>
                </div>
              ))}
            </div>
          </Card.Body>
        </Card>
      </Col> */}
    </Row>
  );
};

export default CalendarBlog;
