import axiosInstance from "../../../services/AxiosInstance";

export async function GetSingleStudent(id) {
  return axiosInstance
    .get("teacher-api/students/" + id)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error fetching student:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
