import axiosInstance from "../../services/AxiosInstance";

export async function GetAllEvents() {
  return axiosInstance
    .get("teacher-api/events")
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching events:", error);
      throw error;
    });
}
