import React from "react";
import { DatePicker } from "rsuite";
import * as Yup from "yup";
import { Formik } from "formik";
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../../layouts/PageTitle";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IoMdInformationCircle } from "react-icons/io";
import Swal from "sweetalert2";
import { queryClient } from "../../../../../App";
import { OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { EditCourseExam } from "../../../../../API/Courses/SingleCourse/CourseExams/EditCourseExam";
import { GetSingleExam } from "../../../../../API/Courses/SingleCourse/CourseExams/GetSingleExam";

const lessonSchema = Yup.object().shape({
  title: Yup.string()
    .notRequired()
    .min(3, "Title must be at least 3 characters")
    .matches(
      /^(?!\d+$)[a-zA-Z\u0600-\u06FF0-9_-\s]*$/,
      "Title must be alphanumeric and can include underscores, dashes, and spaces"
    ),
  description: Yup.string()
    .min(6, "description must consist of at least 6 characters ")
    .notRequired(),
  startDateTime: Yup.date()
    .nullable()
    .notRequired()
    .typeError("Invalid date format")
    .test("is-future-date", "The date must be in the future", (value) => {
      return !value || value > new Date(); // Checks if the date is in the future
    }),
  deadline: Yup.date()
    .notRequired()
    .nullable()
    .typeError("Invalid date format")
    .test("is-future-date", "The date must be in the future", (value) => {
      return !value || value > new Date(); // Checks if the date is in the future
    })
    .test(
      "is-after-start",
      "The deadline must be after the start time",
      function (value) {
        const { startDateTime } = this.parent;
        return (
          !startDateTime || !value || new Date(value) > new Date(startDateTime)
        );
      }
    ),
  duration: Yup.number("Duration must be a number")
    .min(1, "Duration must be at least 1 hour")
    .nullable(),
  isOneTimeAccess: Yup.boolean().required("Please select an option"),
});

const EditExam = () => {
  const navigate = useNavigate();
  const { id, examId } = useParams();

  const {
    data: SingleExam,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["SingleExam", examId],
    queryFn: () => GetSingleExam(id, examId),
  });
  const { mutate } = useMutation({
    mutationFn: (data) => EditCourseExam(id, examId, data),
    onSuccess: () => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Exam Edited Successfully",
      });
      queryClient.invalidateQueries({ queryKey: ["SingleExam"] });
      navigate(`/all-courses/${id}/Exams`);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });
  if (
    isError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        {" "}
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }
  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  const initialValues = {
    title: SingleExam?.title || "",
    description: SingleExam?.description || "",
    duration: SingleExam?.duration || null,
    startDateTime: SingleExam?.startDate
      ? new Date(SingleExam.startDate)
      : null,
    deadline: SingleExam?.deadline ? new Date(SingleExam.deadline) : null,
    isOneTimeAccess: SingleExam?.isOneTimeAccess || false,
  };
  return (
    <>
      <PageTitle
        title={"Course Exams"}
        activeMenu={"All Courses"}
        motherMenu={"Courses"}
        userId={id}
        secondMenu={"Edit Exam"}
        goBack={true}
        goTo={`/all-courses/${id}/Exams`}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit Exam #{examId}</h4>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={lessonSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                const changedValues = Object.keys(values).reduce((acc, key) => {
                  if (values[key] !== initialValues[key]) {
                    acc[key] = values[key]; // Include only changed values
                  }
                  return acc;
                }, {});
                if (Object.keys(changedValues).length === 0) {
                  // If no fields were changed, do not submit and possibly alert the user
                  Swal.fire({
                    icon: "info",
                    title: "No Changes Detected",
                    text: "No changes were made to the form.",
                  });
                  actions.setSubmitting(false);
                  return; // Stop the onSubmit processing here
                }
                mutate(changedValues, {
                  onSuccess: () => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                  },
                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="title">
                            Title
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="title"
                            placeholder="Enter Exam Title"
                            type="text"
                            name="title"
                            className={`form-control ${
                              touched.title
                                ? errors.title
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.title && errors.title && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.title}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="startDateTime">
                            Start Time
                          </label>
                          <DatePicker
                            id="startDateTime"
                            placeholder="Select Open Time"
                            format="yyyy-MM-dd HH:mm:ss" // You can adjust the format as needed
                            style={{
                              width: "100%",
                              borderColor:
                                touched.startDateTime && errors.startDateTime
                                  ? "red"
                                  : "initial",
                              boxShadow: "none",
                            }}
                            value={values.startDateTime}
                            onChange={(value) =>
                              setFieldValue("startDateTime", value)
                            }
                            block // This prop makes the DatePicker take the full width of its parent
                          />
                          {touched.startDateTime && errors.startDateTime && (
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}>
                              {errors.startDateTime}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="deadline">
                            Deadline
                          </label>
                          <DatePicker
                            id="deadline"
                            placeholder="Select Deadline Time"
                            format="yyyy-MM-dd HH:mm:ss" // You can adjust the format as needed
                            style={{
                              width: "100%",
                              borderColor:
                                touched.deadline && errors.deadline
                                  ? "red"
                                  : "initial",
                              boxShadow: "none",
                            }}
                            value={values.deadline}
                            onChange={(value) =>
                              setFieldValue("deadline", value)
                            }
                            block // This prop makes the DatePicker take the full width of its parent
                          />
                          {touched.deadline && errors.deadline && (
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}>
                              {errors.deadline}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="duration">
                            Duration (Hours)
                          </label>
                          <input
                            id="duration"
                            placeholder="Enter Exam Duration"
                            type="text"
                            name="duration"
                            className={`form-control ${
                              touched.duration && errors.duration
                                ? "is-invalid"
                                : touched.duration
                                ? "is-valid"
                                : ""
                            }`}
                            value={values.duration}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.duration && errors.duration && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.duration}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="description">
                            Description <span className="text-danger">*</span>
                          </label>
                          <input
                            id="description"
                            placeholder="Enter Exam Description"
                            type="text"
                            name="description"
                            className={`form-control ${
                              touched.description && errors.description
                                ? "is-invalid"
                                : touched.description
                                ? "is-valid"
                                : ""
                            }`}
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.description && errors.description && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.description}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip
                                id="button-tooltip"
                                className="custom-tooltip">
                                <strong> Policy :</strong> exam can be taken
                                many times or only once.
                              </Tooltip>
                            }>
                            <label className="form-label">
                              Attempt{" "}
                              <IoMdInformationCircle
                                color="#FFAA16"
                                size={18}
                              />
                            </label>
                          </OverlayTrigger>
                          <div className="mb-3">
                            <div className="radio mb-1">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="isOneTimeAccess"
                                  className="form-check-input me-2"
                                  checked={!values.isOneTimeAccess}
                                  onChange={() =>
                                    setFieldValue("isOneTimeAccess", false)
                                  }
                                />
                                Multi-Access
                              </label>
                            </div>
                            <div className="radio">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="isOneTimeAccess"
                                  className="form-check-input me-2"
                                  checked={values.isOneTimeAccess}
                                  onChange={() =>
                                    setFieldValue("isOneTimeAccess", true)
                                  }
                                />
                                One-Time Access
                              </label>
                            </div>
                          </div>
                          {touched.isOneTimeAccess &&
                            errors.isOneTimeAccess && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.isOneTimeAccess}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary me-1">
                          Submit
                        </button>
                        <button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-danger light">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditExam;
