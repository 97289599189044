import axiosInstance from "../../../services/AxiosInstance";

export async function GetStudentCourses(id, title) {
  const params = {
    ...(title && { title }),
  };
  let url = `teacher-api/students/${id}/courses`;

  return axiosInstance
    .get(url, { params })
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error fetching student's courses:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
