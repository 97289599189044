import React, { useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Nav, Tab } from "react-bootstrap";
import { GetSingleStudent } from "../../../API/Students/SingleSutdent/GetSingleStudent";
import Filter from "./Filter";
import Swal from "sweetalert2";
import { SuspendStudent } from "../../../API/Students/SuspendStudent";
import { queryClient } from "../../../App";
import { DeleteStudent } from "../../../API/Students/DeleteStudent";
import Personal from "./StudentsParts/Personal";
import Courses from "./StudentsParts/Courses";
import Submissions from "./StudentsParts/Submissions";
import Exams from "./StudentsParts/Exams";
import Appeal from "./StudentsParts/Appeal";

const AboutStudent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Personal");
  const [filters, setFilters] = useState({
    Personal: {},
    Courses: { search: "", date: "" },
    Submissions: { search: "", date: "" },
    Exams: { search: "", date: "" },
    Appeal: { search: "", date: "" },
  });

  // fetch student personal data
  const {
    data: PersonalData,
    isLoading: PesronalLoading,
    isError: PesronalError,
    error,
  } = useQuery({
    queryKey: ["SingleStudent", id],
    queryFn: () => GetSingleStudent(id),
  });

  const { mutate: SuspendTheStudent } = useMutation({
    mutationFn: () => SuspendStudent(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["SingleStudent"] });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: PersonalData.active
          ? "Student Suspended Successfully"
          : "Student Access Restored Successfully",
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });
  const { mutate } = useMutation({
    mutationFn: () => DeleteStudent(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["AllStudents"] });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });

  function deleteStudent(id) {
    Swal.fire({
      title: "Are you sure to you want to delete this student ?",
      text: "You will not be able to recover this imaginary file !!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        mutate(id);
        Swal.fire(
          "Deleted!",
          `Student with id #${id} has been deleted successfully.`,
          "success"
        );
        navigate("/all-students");
      }
    });
  }

  const handleTabSelect = (selectedKey) => {
    // Update filters based on the selected tab
    setActiveTab(() => selectedKey);
    // Update state or perform actions based on the selected tab
    setFilters((prevFilters) => ({
      ...prevFilters,
      [selectedKey]: { search: "", date: "" },
    }));
  };
  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [activeTab]: newFilters,
    }));
  };

  return (
    <>
      <PageTitle
        activeMenu={"All Students"}
        motherMenu={"Students"}
        userId={id}
        goBack={true}
      />

      <Filter
        activeTab={activeTab}
        filters={filters[activeTab]}
        onFilterChange={handleFilterChange}
      />
      <div className="row">
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <Tab.Container
                        onSelect={handleTabSelect}
                        defaultActiveKey={"Personal"}>
                        <Nav
                          as="ul"
                          className="nav nav-tabs lg:d-flex">
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Personal">Personal</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Courses">Courses</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Submissions">
                              Submissions
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Exams">Exams</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Appeal">Appeal</Nav.Link>
                          </Nav.Item>
                          <Nav className="ms-auto mt-4 mt-lg-0">
                            <div className="d-flex gap-3 align-items-center">
                              <Link
                                to={"/all-students/edit-student/" + id}
                                className="btn sharp btn-primary px-3 ">
                                <i className="fa fa-pencil" />
                                {"  "} Edit
                              </Link>
                              <Link
                                onClick={() => SuspendTheStudent(id)}
                                className="btn sharp btn-primary px-3 ">
                                {!PesronalLoading &&
                                !PesronalError &&
                                PersonalData.active
                                  ? "Suspend"
                                  : "Activate"}
                              </Link>
                              <Link
                                onClick={() => deleteStudent(id)}
                                className="btn sharp btn-danger px-3 ">
                                <i className="fa fa-trash" />
                                {"  "}
                                Delete
                              </Link>
                            </div>
                          </Nav>
                        </Nav>

                        <Tab.Content className="tab-content">
                          <Tab.Pane eventKey={"Personal"}>
                            <Personal
                              PersonalData={PersonalData}
                              PesronalLoading={PesronalLoading}
                              PesronalError={PesronalError}
                              error={error}
                            />
                          </Tab.Pane>
                        </Tab.Content>
                        <Tab.Content className="tab-content">
                          <Tab.Pane eventKey={"Courses"}>
                            {activeTab === "Courses" && (
                              <Courses
                                id={id}
                                title={filters.Courses.search}
                                enrollDate={filters.Courses.date}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"Submissions"}>
                            {activeTab === "Submissions" && (
                              <Submissions
                                id={id}
                                title={filters.Submissions.search}
                                startDate={filters.Submissions.date}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"Exams"}>
                            {activeTab === "Exams" && (
                              <Exams
                                id={id}
                                title={filters.Exams.search}
                                startDate={filters.Exams.date}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"Appeal"}>
                            {activeTab === "Appeal" && (
                              <Appeal
                                id={id}
                                title={filters.Appeal.search}
                                startDate={filters.Appeal.date}
                              />
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutStudent;
