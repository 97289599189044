import axiosInstance from "../../services/AxiosInstance";

export async function GetAllAnnouncements(searchTxt, date) {
  const params = {
    ...(searchTxt && { searchTxt }),
    ...(date && { date }),
  };
  let url = "teacher-api/announces";
  return axiosInstance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching announcements:", error);
      throw error;
    });
}
