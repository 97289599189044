import axiosInstance from "../../../../../services/AxiosInstance";

export async function DeleteSubmissionFeedback(
  courseId,
  assignmentId,
  submissionId
) {
  let url = `teacher-api/courses/${courseId}/assignments/${assignmentId}/submissions/${submissionId}/feedback`;
  return axiosInstance
    .delete(url)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error Deleting course assignment feedback:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
