export const IMAGES = {
  BigImg1: require("../../assets/images/big/img1.jpg"),
  Profile: require("../../assets/images/profile/profile.png"),

  BigImg: require("../../assets/images/big/img1.jpg"),
  BigImg5: require("../../assets/images/big/img5.jpg"),
  Avatar4: require("../../assets/images/avatar/4.jpg"),
  Avatar2: require("../../assets/images/avatar/2.jpg"),
  Avatar3: require("../../assets/images/avatar/3.jpg"),
  Avatar1: require("../../assets/images/avatar/1.jpg"),
  Avatpng5: require("../../assets/images/avatar/5.png"),
  Avatpng1: require("../../assets/images/avatar/1.png"),

  courseBroken: require("../../assets/images/courses/courseBroken.png"),
  course1: require("../../assets/images/courses/pic1.jpg"),
  course2: require("../../assets/images/courses/pic2.jpg"),
  course3: require("../../assets/images/courses/pic3.jpg"),
  course4: require("../../assets/images/courses/pic4.jpg"),
  course5: require("../../assets/images/courses/pic5.jpg"),
  course6: require("../../assets/images/courses/pic6.jpg"),
  course7: require("../../assets/images/courses/pic7.jpg"),
  course8: require("../../assets/images/courses/pic8.jpg"),

  education1: require("../../assets/images/profile/education/pic1.jpg"),
  education2: require("../../assets/images/profile/education/pic2.jpg"),
  education3: require("../../assets/images/profile/education/pic3.jpg"),
  education4: require("../../assets/images/profile/education/pic4.jpg"),
  education5: require("../../assets/images/profile/education/pic5.jpg"),
  education6: require("../../assets/images/profile/education/pic6.jpg"),
  education7: require("../../assets/images/profile/education/pic7.jpg"),
  education8: require("../../assets/images/profile/education/pic8.jpg"),
  education9: require("../../assets/images/profile/education/pic9.jpg"),
  education10: require("../../assets/images/profile/education/pic10.jpg"),

  Profile8: require("../../assets/images/profile/8.jpg"),
  Profile9: require("../../assets/images/profile/9.jpg"),
  Profile10: require("../../assets/images/profile/10.jpg"),

  smallpic1: require("../../assets/images/profile/small/pic1.jpg"),
  smallpic2: require("../../assets/images/profile/small/pic2.jpg"),
  smallpic3: require("../../assets/images/profile/small/pic3.jpg"),
  smallpic4: require("../../assets/images/profile/small/pic4.jpg"),
  smallpic5: require("../../assets/images/profile/small/pic5.jpg"),
  smallpic6: require("../../assets/images/profile/small/pic6.jpg"),
  smallpic7: require("../../assets/images/profile/small/pic7.jpg"),
  smallpic8: require("../../assets/images/profile/small/pic8.jpg"),
  smallpic9: require("../../assets/images/profile/small/pic9.jpg"),
  smallpic10: require("../../assets/images/profile/small/pic10.jpg"),

  tab1: require("../../assets/images/tab/1.jpg"),
};

export const SVGICON = {
  user: (
    <svg
      id="icon-customers"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-user">
      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
      <circle
        cx="12"
        cy="7"
        r="4"></circle>
    </svg>
  ),
  bills: (
    <svg
      id="icon-orders"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-file-text">
      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
      <polyline points="14 2 14 8 20 8"></polyline>
      <line
        x1="16"
        y1="13"
        x2="8"
        y2="13"></line>
      <line
        x1="16"
        y1="17"
        x2="8"
        y2="17"></line>
      <polyline points="10 9 9 9 8 9"></polyline>
    </svg>
  ),
  revenue: (
    <svg
      id="icon-revenue"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-dollar-sign">
      <line
        x1="12"
        y1="1"
        x2="12"
        y2="23"></line>
      <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
    </svg>
  ),
  grid: (
    <svg
      id="icon-database-widget"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-database">
      <ellipse
        cx="12"
        cy="5"
        rx="9"
        ry="3"></ellipse>
      <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
      <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
    </svg>
  ),
};

const appTheme = {
  IMAGES,
  SVGICON,
};
export default appTheme;
