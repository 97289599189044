import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Accordion, Button, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GetExamAppeals } from "../../../../../../API/Courses/SingleCourse/CourseExams/SingleExamAppeals/GetExamAppeals";
import { IMAGES } from "../../../../../constant/theme";

function ExamAppeal({ id, examId, search, date }) {
  const [filteredAppeals, setFilteredAppeals] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showMessage, setShowMessage] = useState(false);

  const theadData = [
    { heading: "ID" },
    { heading: "Student ID" },
    { heading: "Student Name" },
    { heading: "Student Email" },
    { heading: "Appeal Date" },
    { heading: "Message" },
  ];
  const toggleMessage = (id) => {
    if (showMessage === id) {
      setShowMessage(null);
    } else {
      setShowMessage(id);
    }
  };
  const {
    data: ExamAppeals,
    isLoading: isLoadingAppeals,
    isError: isErrorAppeals,
    error,
  } = useQuery({
    queryKey: ["ExamAppeals", id, examId],
    queryFn: () => GetExamAppeals(id, examId),
  });

  useEffect(() => {
    if (ExamAppeals) {
      const filtered = ExamAppeals.filter((appeal) => {
        const matchesTitle = search
          ? appeal.studentId.toString() === search ||
            appeal.id.toString() === search
          : true;

        const matchesDate = date
          ? new Date(appeal.date) === new Date(date)
          : true;
        return matchesTitle && matchesDate;
      });
      setFilteredAppeals(filtered);
      setPageNo(0); // Reset to the first page whenever search term changes
    }
  }, [ExamAppeals, search, examId, date]);

  const totalPages = Math.ceil(filteredAppeals.length / itemsPerPage);

  const paginatedExams = filteredAppeals.slice(
    pageNo * itemsPerPage,
    (pageNo + 1) * itemsPerPage
  );

  if (isLoadingAppeals) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  if (
    isErrorAppeals &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }

  return filteredAppeals.length === 0 ? (
    <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
      <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
        No data found
      </div>
    </div>
  ) : (
    <div className="card-body">
      <div className="table-responsive">
        <div
          id="holidayList"
          className="dataTables_wrapper no-footer">
          <div className="justify-content-between d-sm-flex">
            <div className="dataTables_length">
              <label className="d-flex align-items-center">
                Show
                <Dropdown className="search-drop">
                  <Dropdown.Toggle
                    as="div"
                    className="search-drop-btn">
                    {itemsPerPage}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setItemsPerPage(10)}>
                      10
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(20)}>
                      20
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(30)}>
                      30
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                entries
              </label>
            </div>
          </div>
          <table
            id="example4"
            className="display dataTable no-footer w-100">
            <thead>
              <tr>
                {theadData.map((item, ind) => (
                  <th key={ind}>{item.heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedExams.map((data, ind) => (
                <>
                  <tr key={data.id}>
                    <td>
                      <strong>{data.id}</strong>
                    </td>
                    <td>
                      <strong>{data.studentId}</strong>
                    </td>

                    <td>
                      <img
                        className="rounded-circle me-2"
                        width="35"
                        src={data.studentImage}
                        alt="Person"
                        onError={(e) => {
                          e.target.src = IMAGES.Avatpng1;
                        }}
                      />
                      {data.studentName}
                    </td>

                    <td>{data.studentEmail}</td>
                    <td>{new Date(data.date).toDateString()}</td>
                    <td
                      colSpan={1}
                      className="ms-5">
                      <Button
                        onClick={() => toggleMessage(data.id)}
                        className="btn sharp btn-primary d-flex justify-content-center align-items-center gap-2 w-100">
                        <span>Message</span>
                        {!showMessage ? (
                          <i class="fa-solid fa-eye" />
                        ) : (
                          <i class="fa-solid fa-eye-slash" />
                        )}
                      </Button>
                    </td>
                  </tr>
                  {showMessage === data.id && (
                    <tr>
                      <td colSpan="5">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Body>{data.message}</Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </td>
                    </tr>
                  )}
                  {/* {showMessage === data.id && (
                    <tr>
                      <td colSpan="5">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Message</Accordion.Header>
                            <Accordion.Body>{data.message}</Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </td>
                    </tr>
                  )} */}
                </>
              ))}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              Showing {pageNo * itemsPerPage + 1} to{" "}
              {filteredAppeals.length > (pageNo + 1) * itemsPerPage
                ? (pageNo + 1) * itemsPerPage
                : filteredAppeals.length}{" "}
              of {filteredAppeals.length} entries
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate">
              <Link
                className="paginate_button previous disabled"
                to="#"
                onClick={() => {
                  if (pageNo > 0) setPageNo(pageNo - 1);
                }}>
                Previous
              </Link>
              <span>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (number, i) => (
                    <Link
                      key={i}
                      to="#"
                      className={`paginate_button ${
                        pageNo === i ? "current" : ""
                      }`}
                      onClick={() => setPageNo(i)}>
                      {number}
                    </Link>
                  )
                )}
              </span>
              <Link
                className="paginate_button next"
                to="#"
                onClick={() => {
                  if (pageNo < totalPages - 1) setPageNo(pageNo + 1);
                }}>
                Next
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamAppeal;
