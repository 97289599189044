import axiosInstance from "../../../../../services/AxiosInstance";

export async function EditExamQuestionMCQ(data, courseId, examId, questionId) {
  let url = `teacher-api/courses/${courseId}/exams/${examId}/questions/mcq-questions/${questionId}`;
  return axiosInstance
    .put(url, data)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error adding course exams:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
export async function EditExamQuestionText(data, courseId, examId, questionId) {
  let url = `teacher-api/courses/${courseId}/exams/${examId}/questions/text-questions/${questionId}`;
  return axiosInstance
    .put(url, data)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error adding course exams:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
