import axiosInstance from "../../../../services/AxiosInstance";

export async function RenewCourseSubscription(courseId, studentId) {
  let url = `/teacher-api/courses/${courseId}/students/${studentId}/renew`;
  return axiosInstance
    .get(url)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error getting course students:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
