import React, { useState } from "react";
import Select from "react-select";
import PageTitle from "../../layouts/PageTitle";
import { DatePicker } from "rsuite";
import { Loader, Row } from "rsuite";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GetMyProfile } from "../../../API/MyProfile/GetMyProfile";
import { UpdateMyProfile } from "../../../API/MyProfile/UpdateMyProfile";
import { queryClient } from "../../../App";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import { PROFILEIMG, UploadToS3 } from "../../../util/UploadToS3";
import { IMAGES } from "../../constant/theme";
import { COUNTRIES } from "../../../util/Countries";
import { DebounceFun } from "../../../util/DebounceFun";
import { CheckEmailPhoneTeacher } from "../../../API/CheckEmailPhoneTeacher";
import { TokenDecode } from "../../../util/TokenDecode";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/;
const debouncedCheckEmail = DebounceFun(
  (email) => CheckEmailPhoneTeacher(email, ""),
  500
);
const debouncedCheckPhone = DebounceFun(
  (phone) => CheckEmailPhoneTeacher("", phone),
  500
);

const EditMyProfile = () => {
  const [imagePreview, setImagePreview] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const {
    data: MyProfile,
    isLoading: MyProfileLoading,
    isError: MyProfileError,
    error,
  } = useQuery({ queryKey: ["MyProfile"], queryFn: () => GetMyProfile() });

  const { mutate } = useMutation({
    mutationFn: (data) => UpdateMyProfile(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["MyProfile"] });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Profile Updated Successfully",
      });
      navigate("/my-profile");
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });

  if (
    MyProfileError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }

  if (MyProfileLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  const initialValues = {
    firstName: MyProfile.firstName || "",
    lastName: MyProfile.lastName || "",
    password: "",
    confirmPassword: "",
    email: MyProfile.email || "",
    phone: MyProfile.phone || "",
    language: MyProfile.language || "",
    country: MyProfile.country || "",
    experience: MyProfile.experience || "",
    overview: MyProfile.overview || "",
    jobTitle: MyProfile.jobTitle || "",
    image: MyProfile.image || null,
  };

  const languages = [
    { value: "Arabic", label: "Arabic" },
    { value: "English", label: "English" },
  ];

  const loginSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, "Your first name must consist of at least 3 characters")
      .max(50, "Your first name must consist of at most 50 characters")
      .matches(
        /^[a-zA-Z\s-_]*$/,
        "First Name must contain only letters, spaces, underscores, and dashes"
      ),
    lastName: Yup.string()
      .min(3, "Your last name must consist of at least 3 characters")
      .max(50, "Your last name must consist of at most 50 characters")
      .matches(
        /^[a-zA-Z\s-_]*$/,
        "Last Name must contain only letters, spaces, underscores, and dashes"
      ),
    password: Yup.string()
      .min(5, "Your password must be at least 5 characters long")
      .max(50, "Your password must be at least 5 characters long")
      .matches(
        passwordRegex,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    email: Yup.string()
      .email("Invalid email")
      .required("Please provide an email")
      .test(
        "is-email-unique",
        "Email is already in use",
        async function (value) {
          // Only proceed with checking if the email has actually been changed
          if (!value || value === initialValues.email) return true;
          try {
            const data = await debouncedCheckEmail(value);
            if (data.emailExists) {
              return this.createError({
                path: this.path,
                message: "Email is already in use",
              });
            }
            return true;
          } catch (error) {
            console.error("Error checking email:", error);
            return this.createError({
              path: this.path,
              message: "Failed to validate email",
            });
          }
        }
      ),

    phone: Yup.number()
      .required("Please provide a phone number")
      .typeError("Phone number must be a number")
      .positive("Phone number must be a positive number")
      .test(
        "is-phone-unique",
        "Phone number is already in use",
        async function (value) {
          // Only check for uniqueness if the phone number has changed
          if (!value || value === initialValues.phone) return true;
          try {
            const data = await debouncedCheckPhone(value);
            if (data.phoneExists) {
              return this.createError({
                path: this.path,
                message: "Phone number is already in use",
              });
            }
            return true;
          } catch (error) {
            console.error("Error checking phone:", error);
            return this.createError({
              path: this.path,
              message: "Failed to validate phone number",
            });
          }
        }
      )
      .test(
        "isAtLeastTwoDigits",
        "Phone number must be at least 11 digits",
        (number) => number && number.toString().length > 9
      )
      .test(
        "isAtLeastTwoDigits",
        "Phone number must be at max 11 digits",
        (number) => number && number.toString().length <= 11
      ),
    experience: Yup.number()
      .required("Please provide an Experience years")
      .typeError("Experience years must be a number")
      .positive("Experience years must be a positive number")
      .test(
        "isAtLeastTwoDigits",
        "Experience years must be at least 1 digits",
        (number) => number && number.toString().length >= 1
      ),
    language: Yup.string().required("Please select a language"),
    overview: Yup.string().required("Please enter an overview").min(10),
    jobTitle: Yup.string().required("Please enter a job title"),
    country: Yup.string().notOneOf([""], "Please select a country"),
  });

  const handleImageChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("image", file);
    setImagePreview(URL.createObjectURL(file));
  };

  const customStyles = (error, touched) => ({
    control: (provided) => ({
      ...provided,
      borderColor:
        touched && error
          ? "red"
          : touched && !error
          ? "#7ed321"
          : provided.borderColor,
      "&:hover": {
        borderColor:
          touched && error
            ? "red"
            : touched && !error
            ? "#7ed321"
            : provided.borderColor,
      },
      boxShadow:
        touched && error
          ? "0 0 0 1px red"
          : touched
          ? "0 0 0 1px #7ed321"
          : "none",
    }),
  });

  const communityId = TokenDecode(localStorage.getItem("token"))[
    "community-id"
  ];

  return (
    <>
      <PageTitle
        activeMenu={"Edit My Profile"}
        motherMenu={"Profile"}
      />
      <div className="row">
        <div className="col-xl-12 col-xxl-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">My Info</h5>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={loginSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                const changedValues = Object.keys(values).reduce((acc, key) => {
                  if (values[key] !== initialValues[key]) {
                    acc[key] = values[key]; // Include only changed values
                  }
                  return acc;
                }, {});
                actions.setSubmitting(true);
                mutate(changedValues, {
                  onSuccess: (data) => {
                    actions.setSubmitting(false);
                    if (changedValues.image) {
                      // Check if image has changed
                      UploadToS3(
                        data.image, // Assuming `data.image` is the path where the image should be saved on S3
                        changedValues.image,
                        `Teacher/${communityId}/ProfileImg/`,
                        true
                      );
                    }
                  },

                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="p-4">
                        <div className="author-profile">
                          <div className="author-media">
                            <img
                              src={
                                imagePreview
                                  ? imagePreview
                                  : PROFILEIMG + MyProfile?.image
                              }
                              alt="ProfileImg"
                              onError={(e) => {
                                e.target.src = IMAGES.Avatpng1;
                              }}
                            />
                            <div
                              className="upload-link"
                              data-toggle="tooltip"
                              data-placement="right"
                              data-original-title="update">
                              <input
                                id="image"
                                name="image"
                                type="file"
                                className="update-flie"
                                onChange={(event) =>
                                  handleImageChange(event, setFieldValue)
                                }
                                onBlur={handleBlur}
                              />
                              <i className="fa fa-camera"></i>
                            </div>
                          </div>
                          {errors.image && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.image}
                            </div>
                          )}
                          <div className="author-info">
                            <h6 className="title">
                              {MyProfile?.firstName + " " + MyProfile?.lastName}
                            </h6>
                            <span>#{MyProfile.id}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="firstName">
                            First Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="firstName"
                            placeholder="Enter First Name"
                            type="text"
                            name="firstName"
                            className={`form-control ${
                              touched.firstName
                                ? errors.firstName
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.firstName && errors.firstName && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.firstName}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="lastName">
                            Last Name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="lastName"
                            placeholder="Enter Last Name"
                            type="text"
                            name="lastName"
                            className={`form-control ${
                              touched.lastName
                                ? errors.lastName
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.lastName && errors.lastName && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.lastName}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="Email">
                            Email
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="Email"
                            placeholder="Email"
                            type="email"
                            name="email"
                            className={`form-control ${
                              touched.email
                                ? errors.email
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.email && errors.email && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="phone">
                            Phone Number
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="phone"
                            placeholder="Phone Number"
                            type="text"
                            name="phone"
                            className={`form-control ${
                              touched.phone
                                ? errors.phone
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.phone && errors.phone && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.phone}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.password
                              ? errors.password
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label
                            className="form-label"
                            htmlFor="Password">
                            Password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              id="Password"
                              placeholder="Password"
                              type={`${showPassword ? "text" : "password"}`}
                              name="password"
                              className={`form-control ${
                                touched.password
                                  ? errors.password
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="input-group-text show-validate cursor-pointer"
                              onClick={() => setShowPassword(!showPassword)}>
                              {showPassword === false ? (
                                <i className="fa fa-eye-slash" />
                              ) : (
                                <i className="fa fa-eye" />
                              )}
                            </div>
                            {touched.password && errors.password && (
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.confirmPassword
                              ? errors.confirmPassword
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label
                            className="form-label"
                            htmlFor="confirmPassword">
                            Confirm Password
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              id="confirmPassword"
                              placeholder="Confirm Password"
                              type={`${showPassword ? "text" : "password"}`}
                              name="confirmPassword"
                              className={`form-control ${
                                touched.confirmPassword
                                  ? errors.confirmPassword
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="input-group-text show-validate cursor-pointer"
                              onClick={() => setShowPassword(!showPassword)}>
                              {showPassword === false ? (
                                <i className="fa fa-eye-slash" />
                              ) : (
                                <i className="fa fa-eye" />
                              )}
                            </div>
                            {touched.confirmPassword &&
                              errors.confirmPassword && (
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp">
                                  {errors.confirmPassword}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div
                          className={`form-group mb-3 ${
                            values.language
                              ? errors.language
                                ? "is-invalid"
                                : "is-valid"
                              : ""
                          }`}>
                          <label className="form-label">
                            Language
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="language"
                            id="language"
                            placeholder="Select Language"
                            isSearchable={false}
                            options={languages}
                            styles={customStyles(
                              errors.language,
                              touched.language
                            )}
                            className={`custom-react-select ${
                              touched.language
                                ? errors.language
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={languages.find(
                              (option) => option.value === values.language
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "language",
                                option ? option.value : ""
                              );
                              setFieldTouched("language", true, false); // mark as touched but do not validate yet
                              validateField("language"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("language", true)}
                          />
                          {touched.language && errors.language && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.language}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="country"
                            className="form-label">
                            Country
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="country"
                            id="country"
                            placeholder="Select Country"
                            isSearchable={true}
                            options={COUNTRIES}
                            styles={customStyles(
                              errors.country,
                              touched.country
                            )}
                            className={`custom-react-select ${
                              touched.country
                                ? errors.country
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={COUNTRIES.find(
                              (option) => option.value === values.country
                            )}
                            onChange={(option) => {
                              setFieldValue(
                                "country",
                                option ? option.value : ""
                              );
                              setFieldTouched("country", true, false); // mark as touched but do not validate yet
                              validateField("country"); // explicitly validate the field
                            }}
                            onBlur={() => setFieldTouched("country", true)}
                          />
                          {touched.country && errors.country && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.country}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="jobTitle">
                            Job Title
                          </label>
                          <input
                            id="jobTitle"
                            placeholder="Enter Job Title"
                            type="text"
                            name="jobTitle"
                            className={`form-control ${
                              touched.jobTitle
                                ? errors.jobTitle
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.jobTitle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.jobTitle && errors.jobTitle && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.jobTitle}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="experience">
                            Experience Years
                          </label>
                          <input
                            id="experience"
                            placeholder="Enter experience"
                            type="text"
                            name="experience"
                            className={`form-control ${
                              touched.experience
                                ? errors.experience
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.experience}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.experience && errors.experience && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.experience}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="overview">
                            Overview
                          </label>
                          <input
                            id="overview"
                            placeholder="Enter overview"
                            type="text"
                            name="overview"
                            className={`form-control ${
                              touched.overview
                                ? errors.overview
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.overview}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.overview && errors.overview && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.overview}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary me-1"
                          disabled={isSubmitting}>
                          {isSubmitting ? <Loader /> : "Submit"}
                        </button>
                        <button
                          onClick={() => navigate("/all-students")}
                          type="button"
                          className="btn btn-danger light">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMyProfile;
