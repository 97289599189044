import axiosInstance from "../../../services/AxiosInstance";

export async function GetStudentSubmition(id, date) {
  const params = {
    ...(date && { date }),
  };
  let url = `teacher-api/students/${id}/submission`;
  return axiosInstance
    .get(url, { params })
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error fetching student's submission:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
