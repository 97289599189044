import axiosInstance from "../../services/AxiosInstance";

export async function AddNewAnnouncment(data) {
  let url = `teacher-api/announces`;
  return axiosInstance
    .post(url, data)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error Adding new announcement:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
