import axiosInstance from "../../../../services/AxiosInstance";

export async function GetCourseRates(courseId) {
  let url = `teacher-api/courses/${courseId}/rates`;
  return axiosInstance
    .get(url)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error getting course rates:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
