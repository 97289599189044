import { useQuery } from "@tanstack/react-query";
import { Dropdown, Row, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GetStudentSubmition } from "../../../../API/Students/SingleSutdent/GetStudentSubmition";
import { COURSEFILES } from "../../../../util/UploadToS3";

function Submissions({ id, title, startDate }) {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);

  const theadData = [
    { heading: "ID." },
    { heading: "Assignment Title" },
    { heading: "Submission Date" },
    { heading: "Score" },
    { heading: "Evaluated" },
    { heading: "Feedback Date" },
    { heading: "Action" },
  ];

  const {
    data: SubmissionsData,
    isLoading: SubmissionsLoading,
    isError: SubmissionsError,
    error,
  } = useQuery({
    queryKey: ["SingleStudentSubmissions", id, startDate],
    queryFn: () => GetStudentSubmition(id, startDate),
  });

  useEffect(() => {
    if (SubmissionsData) {
      const filtered = SubmissionsData.filter((submission) =>
        title
          ? submission.id.toString().includes(title) ||
            submission.assignmentTitle.toLowerCase().includes(title)
          : true
      );
      setFilteredSubmissions(filtered);
      setPageNo(0); // Reset to the first page whenever search term changes
    }
  }, [SubmissionsData, title]);

  const totalPages = Math.ceil(filteredSubmissions.length / itemsPerPage);

  const paginatedSubmissions = filteredSubmissions.slice(
    pageNo * itemsPerPage,
    (pageNo + 1) * itemsPerPage
  );

  if (
    SubmissionsError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }
  if (SubmissionsLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  return filteredSubmissions.length === 0 ? (
    <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
      <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
        No data found
      </div>
    </div>
  ) : (
    <div className="card-body">
      <div className="table-responsive">
        <div
          id="holidayList"
          className="dataTables_wrapper no-footer">
          <div className="justify-content-between d-sm-flex">
            <div className="dataTables_length">
              <label className="d-flex align-items-center">
                Show
                <Dropdown className="search-drop">
                  <Dropdown.Toggle
                    as="div"
                    className="search-drop-btn">
                    {itemsPerPage}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setItemsPerPage(10)}>
                      10
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(20)}>
                      20
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(30)}>
                      30
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                entries
              </label>
            </div>
          </div>
          <table
            id="example4"
            className="display dataTable no-footer w-100">
            <thead>
              <tr>
                {theadData.map((item, ind) => (
                  <th key={ind}>{item.heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedSubmissions.map((data, ind) => (
                <tr key={ind}>
                  <td>
                    <strong>{data.id}</strong>
                  </td>
                  <td>
                    <strong>{data.assignmentTitle}</strong>
                  </td>
                  <td>{new Date(data.submitTime).toDateString()}</td>
                  <td>{data.score || 0}%</td>
                  <td>
                    {data.evaluated === true ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#7ED321"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#FF1616"
                        className="bi bi-x-circle-fill"
                        viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                      </svg>
                    )}
                  </td>
                  <td>
                    {data.feedbackDate
                      ? new Date(data.feedbackDate).toDateString()
                      : "No feedback yet"}
                  </td>

                  <td>
                    <Button
                      disabled={!data.evaluated}
                      variant="primary">
                      <Link
                        className="text-white"
                        to={COURSEFILES + data.submitFile}>
                        Donwload Submission
                      </Link>
                    </Button>
                    {/* removerd for now  */}
                    {/* <Dropdown className="custom-dropdown mb-0 ms-4 cursor-pointer">
                                <Dropdown.Toggle
                                  as="div"
                                  className="i-false">
                                  <i className="fa fa-ellipsis-v fa-2x" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  align="end"
                                  className="dropdown-menu-right">
                                  <Dropdown.Item
                                    href={COURSEFILES + data.submitFile}
                                    className="text-primary">
                                    Download submission
                                  </Dropdown.Item>
                                  {data.evaluated ? (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleOpenFeedbackModal(data.id)
                                      }
                                      className="text-primary">
                                      Edit Feedback
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleOpenFeedbackModal(data.id)
                                      }
                                      className="text-primary">
                                      Add Feedback
                                    </Dropdown.Item>
                                  )}
                                  <Modal
                                    className="fade bd-example-modal-lg modal-dialog-centered"
                                    show={editFeedback}
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    onHide={handleCloseFeedbackModal}>
                                    <Modal.Header>
                                      <Modal.Title>
                                        {!data.evaluated
                                          ? "Add Feedback"
                                          : `Edit Feedback #${data.id}`}
                                      </Modal.Title>
                                      <Button
                                        variant=""
                                        className="btn-close"
                                        onClick={
                                          handleCloseFeedbackModal
                                        }></Button>
                                    </Modal.Header>
                                    <Modal.Body>
                                      <Formik
                                        initialValues={{
                                          score: data.evaluated
                                            ? data.score
                                            : "",
                                          file: null,
                                          title: data.evaluated
                                            ? data.feedbackTitle
                                            : "",
                                        }}
                                        validationSchema={loginSchema}
                                        // validateOnBlur={true}
                                        // validateOnChange={true}
                                        onSubmit={(values, actions) => {
                                          const { file, ...resData } = values;

                                          data.evaluated
                                            ? editFeedbackMutation(resData, {
                                                onSuccess: (data) => {
                                                  actions.resetForm();
                                                  actions.setSubmitting(false);
                                                  navigate(
                                                    `/all-students/${id}`
                                                  );
                                                  Swal.fire({
                                                    icon: "success",
                                                    title: "Success",
                                                    text: "Feedback Edited Successfully",
                                                  });
                                                  UploadToS3(
                                                    data.feedback,
                                                    file,
                                                    "Teacher/CourseFiles/",
                                                    true
                                                  );
                                                },
                                                onError: () => {
                                                  actions.setSubmitting(false);
                                                },
                                              })
                                            : addFeedbackMutation(resData, {
                                                onSuccess: (data) => {
                                                  actions.resetForm();
                                                  actions.setSubmitting(false);
                                                  navigate(
                                                    `/all-students/${id}`
                                                  );
                                                  Swal.fire({
                                                    icon: "success",
                                                    title: "Success",
                                                    text: "Feedback Added Successfully",
                                                  });
                                                  UploadToS3(
                                                    data.feedback,
                                                    file,
                                                    "Teacher/CourseFiles/",
                                                    true
                                                  );
                                                },
                                                onError: () => {
                                                  actions.setSubmitting(false);
                                                },
                                              });
                                        }}>
                                        {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          setFieldValue,
                                        }) => (
                                          <div className="card-body">
                                            <form onSubmit={handleSubmit}>
                                              <div className="row">
                                                <div className="col-sm-6">
                                                  <div className="form-group">
                                                    <label
                                                      className="form-label"
                                                      htmlFor="title">
                                                      Title
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      id="title"
                                                      placeholder="Enter title"
                                                      type="text"
                                                      name="title"
                                                      className={`form-control ${
                                                        touched.title
                                                          ? errors.title
                                                            ? "is-invalid"
                                                            : "is-valid"
                                                          : ""
                                                      }`}
                                                      value={values.title}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                    />
                                                    {touched.title &&
                                                      errors.title && (
                                                        <div
                                                          id="val-username1-error"
                                                          className="invalid-feedback animated fadeInUp">
                                                          {errors.title}
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>

                                                <div className="col-sm-6">
                                                  <div className="form-group">
                                                    <label
                                                      className="form-label"
                                                      htmlFor="score">
                                                      Score
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      id="score"
                                                      placeholder="Enter Score"
                                                      type="text"
                                                      name="score"
                                                      className={`form-control ${
                                                        touched.score
                                                          ? errors.score
                                                            ? "is-invalid"
                                                            : "is-valid"
                                                          : ""
                                                      }`}
                                                      value={values.score}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                    />
                                                    {touched.score &&
                                                      errors.score && (
                                                        <div
                                                          id="val-username1-error"
                                                          className="invalid-feedback animated fadeInUp">
                                                          {errors.score}
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                                <div className="col-sm-6">
                                                  <div className="form-group">
                                                    <label
                                                      htmlFor="file"
                                                      className="form-label">
                                                      File
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      className={`form-control ${
                                                        touched.file
                                                          ? errors.file
                                                            ? "is-invalid"
                                                            : "is-valid"
                                                          : ""
                                                      }`}
                                                      type="file"
                                                      id="file"
                                                      name="file"
                                                      onChange={(e) => {
                                                        setFieldValue(
                                                          "file",
                                                          e.currentTarget
                                                            .files[0]
                                                        );
                                                        setFieldValue(
                                                          "feedback",
                                                          e.currentTarget
                                                            .files[0].name
                                                        );
                                                        setFieldValue(
                                                          "size",
                                                          e.currentTarget
                                                            .files[0].size /
                                                            1024
                                                        );
                                                        console.log(
                                                          e.currentTarget
                                                            .files[0]
                                                        );
                                                      }}
                                                    />
                                                    {touched.file &&
                                                      errors.file && (
                                                        <div
                                                          id="val-username1-error"
                                                          className="invalid-feedback animated fadeInUp">
                                                          {errors.file}
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                              </div>

                                              <Modal.Footer>
                                                <Button
                                                  variant="danger light"
                                                  onClick={
                                                    handleCloseFeedbackModal
                                                  }>
                                                  Close
                                                </Button>
                                                <Button
                                                  type="submit"
                                                  variant="primary">
                                                  Save changes
                                                </Button>
                                              </Modal.Footer>
                                            </form>
                                          </div>
                                        )}
                                      </Formik>
                                    </Modal.Body>
                                  </Modal>
                                  {data.evaluated && (
                                    <Dropdown.Item
                                      className="text-danger"
                                      onClick={() => deleteFeedback(data.id)}>
                                      Delete Feedback
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              Showing {pageNo * itemsPerPage + 1} to{" "}
              {filteredSubmissions.length > (pageNo + 1) * itemsPerPage
                ? (pageNo + 1) * itemsPerPage
                : filteredSubmissions.length}{" "}
              of {filteredSubmissions.length} entries
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate">
              <Link
                className="paginate_button previous disabled"
                to="#"
                onClick={() => {
                  if (pageNo > 0) setPageNo(pageNo - 1);
                }}>
                Previous
              </Link>
              <span>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (number, i) => (
                    <Link
                      key={i}
                      to="#"
                      className={`paginate_button ${
                        pageNo === i ? "current" : ""
                      }`}
                      onClick={() => setPageNo(i)}>
                      {number}
                    </Link>
                  )
                )}
              </span>
              <Link
                className="paginate_button next"
                to="#"
                onClick={() => {
                  if (pageNo < totalPages - 1) setPageNo(pageNo + 1);
                }}>
                Next
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Submissions;
