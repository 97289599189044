import axiosInstance from "../../../../services/AxiosInstance";

export async function GetSingleExam(courseId, examId) {
  let url = `teacher-api/courses/${courseId}/exams/${examId}`;
  return axiosInstance
    .get(url)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error getting course exams:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
