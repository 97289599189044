import React, { useContext } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
/// Css
import "./../index.css";
import "./../chart.css";
import "./../step.css";

/// Layout
import Nav from "./../layouts/nav";
import Footer from "./../layouts/Footer";

import { ThemeContext } from "../../context/ThemeContext";
//Scroll To Top
import ScrollToTop from "./../layouts/ScrollToTop";

/// Dashboard
import Home from "./../pages/dashboard/Home";

import EmptyPage from "./../pages/dashboard/EmptyPage";

//Event
import EventManagement from "./../pages/dashboard/EventManagement";

//Student
import AllStudent from "./../pages/students/AllStudent";
import AddStudent from "./../pages/students/AddStudent";
import EditStudent from "./../pages/students/EditStudent";
import AboutStudent from "./../pages/students/AboutStudent";

//Course
import AllCourses from "./../pages/courses/AllCourses";
import AddCourses from "./../pages/courses/AddCourses";
import EditCourses from "./../pages/courses/EditCourses";
import AboutCourses from "./../pages/courses/AboutCourses";

/// Charts
import RechartJs from "./../pages/charts/rechart";
import ChartJs from "./../pages/charts/Chartjs";
import SparklineChart from "./../pages/charts/Sparkline";
import ApexChart from "./../pages/charts/apexcharts";

/// Pages
import LockScreen from "./../pages/error/LockScreen";
import Error400 from "./../pages/error/Error400";
import Error403 from "./../pages/error/Error403";
import Error404 from "./../pages/error/Error404";
import Error500 from "./../pages/error/Error500";
import Error503 from "./../pages/error/Error503";
import AllAnnouncements from "../pages/Announcements/AllAnnouncements";
import AddLesson from "../pages/courses/CourseParts/Lesssons/AddLesson";
import EditLesson from "../pages/courses/CourseParts/Lesssons/EditLesson";
import AddLessonVideo from "../pages/courses/CourseParts/Lesssons/AddLessonVideo";
import AddLessonFile from "../pages/courses/CourseParts/Lesssons/AddLessonFile";
import AddAssignment from "../pages/courses/CourseParts/Assignments/AddAssignment";
import EditAssignment from "../pages/courses/CourseParts/Assignments/EditAssignment";
import AssginmentsPage from "../pages/courses/CourseParts/Assignments/Submissions/AssginmentsPage";
import AddExam from "../pages/courses/CourseParts/Exams/AddExam";
import EditExam from "../pages/courses/CourseParts/Exams/EditExam";
import SingleExam from "../pages/courses/CourseParts/Exams/SingleExam";
import AddExamQuestion from "../pages/courses/CourseParts/Exams/ExamParts/AddExamQuestion";
import EditExamQuestion from "../pages/courses/CourseParts/Exams/ExamParts/EditExamQuestion";
import ExamSubbmitionsFeedback from "../pages/courses/CourseParts/Exams/ExamParts/ExamSubbmitionsFeedback";
import MyProfile from "../pages/MyProfile/MyProfile";
import EditMyProfile from "../pages/MyProfile/EditMyProfile";
import Transactions from "../pages/Transactions/Transactions";
import AddAnnouncement from "../pages/Announcements/AddAnnouncement";
import EditAnnouncement from "../pages/Announcements/EditAnnouncement";

// import Todo from './pages/Todo';

const Markup = () => {
  const allroutes = [
    /// Dashboard
    {
      url: "",
      component: (
        <Navigate
          to="/dashboard"
          replace
        />
      ),
    },
    // { url: "dashboard", component: <div>test</div> },
    { url: "dashboard", component: <Home /> },
    // { url: "index-2", component: <Dashboard2 /> },
    // { url: "index-3", component: <Dashboard3 /> },
    { url: "events", component: <EventManagement /> },

    //Profile
    // { url: "all-professors", component: <AllProfessor /> },
    // { url: "add-professor", component: <AddProfessor /> },
    { url: "my-profile", component: <MyProfile /> },
    { url: "my-profile/edit-my-profile", component: <EditMyProfile /> },

    //Student
    { url: "all-students", component: <AllStudent /> },
    { url: "all-students/add-student", component: <AddStudent /> },
    { url: "all-students/edit-student/:id", component: <EditStudent /> },
    {
      url: "/all-students/:id/submissions?/:submissionId?/feedback?/:feedbackId?",
      component: <AboutStudent />,
    },

    //Registeration
    { url: "all-registerations", component: <div>registeration</div> },
    { url: "add-registeration", component: <div>registeration</div> },
    { url: "edit-registeration", component: <div>registeration</div> },
    { url: "/all-registerations/:id", component: <div>registeration</div> },
    //Messages
    { url: "all-messages", component: <div>Messages</div> },
    { url: "add-messages", component: <div>Messages</div> },
    { url: "edit-messages", component: <div>Messages</div> },
    { url: "/all-messagess/:id", component: <div>Messages</div> },

    //Course
    { url: "all-courses", component: <AllCourses /> },
    { url: "all-courses/add-course", component: <AddCourses /> },
    { url: "all-courses/edit-course/:id", component: <EditCourses /> },
    { url: "all-courses/:id/:tab?", component: <AboutCourses /> },
    { url: "all-courses/:id/add-lesson", component: <AddLesson /> },
    {
      url: "all-courses/:id/add-lesson-file/:lessonId",
      component: <AddLessonFile />,
    },
    {
      url: "all-courses/:id/add-lesson-video/:lessonId",
      component: <AddLessonVideo />,
    },
    { url: "all-courses/:id/edit-lesson/:lessonId", component: <EditLesson /> },

    { url: "all-courses/:id/add-assignment", component: <AddAssignment /> },
    {
      url: "all-courses/:id/edit-assignment/:assignmentId",
      component: <EditAssignment />,
    },
    {
      url: "all-courses/:id/submissions/:submissionId/feedback?/:feedbackId?",
      component: <AssginmentsPage />,
    },
    {
      url: "all-courses/:id/Exams?/add-exam",
      component: <AddExam />,
    },
    {
      url: "all-courses/:id/exams?/edit-exam/:examId",
      component: <EditExam />,
    },
    {
      url: "all-courses/:id/Exams?/:examId/:tab?",
      component: <SingleExam />,
    },
    {
      url: "all-courses/:id/Exams?/:examId/Questions?/Add-question",
      component: <AddExamQuestion />,
    },
    {
      url: "all-courses/:id/Exams?/:examId/Questions?/:questionId/edit",
      component: <EditExamQuestion />,
    },
    {
      url: "all-courses/:id/Exams?/:examId/Submissions?/:submissionId/feedback?",
      component: <ExamSubbmitionsFeedback />,
    },
    {
      url: "all-courses/:id/Exams?/:examId/:tab?",
      component: <SingleExam />,
    },

    // Transactions
    { url: "all-transactions", component: <Transactions /> },
    // Announcements
    { url: "all-announcements", component: <AllAnnouncements /> },
    {
      url: "all-announcements/add-announcement",
      component: <AddAnnouncement />,
    },
    {
      url: "all-announcements/:id/edit-announcement",
      component: <EditAnnouncement />,
    },
    /// pages
    { url: "empty", component: <EmptyPage /> },
    // { url: 'todo', component: <Todo/> },
  ];

  function NotFound() {
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    if (url.indexOf(path) <= 0) {
      return <Error404 />;
    }
  }

  return (
    <>
      <Routes>
        <Route
          path="/page-lock-screen"
          element={<LockScreen />}
        />
        <Route
          path="/page-error-400"
          element={<Error400 />}
        />
        <Route
          path="/page-error-403"
          element={<Error403 />}
        />
        <Route
          path="/page-error-404"
          element={<Error404 />}
        />
        <Route
          path="/page-error-500"
          element={<Error500 />}
        />
        <Route
          path="/page-error-503"
          element={<Error503 />}
        />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { sidebariconHover } = useContext(ThemeContext);
  const sideMenu = useSelector((state) => state.sideMenu);
  return (
    <>
      <div
        id="main-wrapper"
        className={`show  ${sidebariconHover ? "iconhover-toggle" : ""} ${
          sideMenu ? "menu-toggle" : ""
        }`}>
        <Nav />
        <div className="content-body">
          <div
            className="container-fluid"
            style={{ minHeight: window.screen.height - 45 }}>
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Markup;
