import React from "react";
import { DatePicker } from "rsuite";
import { Row } from "react-bootstrap";

import * as Yup from "yup";
import { Formik } from "formik";
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../../layouts/PageTitle";
import { useMutation, useQuery } from "@tanstack/react-query";
import Swal from "sweetalert2";
import { queryClient } from "../../../../../App";

import { GetSingleAssignment } from "../../../../../API/Courses/SingleCourse/CourseAssignments/GetSingleAssignment";
import { EditAssignmentAPI } from "../../../../../API/Courses/SingleCourse/CourseAssignments/EditAssignment";

const lessonSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Title must be at least 3 characters")
    .matches(
      /^(?=.*[a-zA-Z\u0600-\u06FF])[a-zA-Z\u0600-\u06FF0-9 _-]*$/,
      "Title must contain letters and can include numbers, underscores, dashes, and spaces, but cannot contain special characters like @#$%"
    )
    .notOneOf([/^\d+$/], "Title cannot consist of numbers only"),

  openDate: Yup.date()
    .nullable()
    .notRequired()
    .typeError("Invalid date format")
    .test("is-future-date", "The date must be in the future", (value) => {
      return !value || value > new Date(); // Checks if the date is in the future
    }),

  deadline: Yup.date()
    .typeError("Invalid date format")
    .test("is-future-date", "The date must be in the future", (value) => {
      return !value || value > new Date(); // Checks if the date is in the future
    })
    .test(
      "is-after-openDate",
      "Deadline must be after the open time",
      function (value) {
        const { openDate } = this.parent;
        return !openDate || !value || value > openDate;
      }
    ),

  description: Yup.string()
    .min(3, "Description must consist of at least 3 characters")
    .required("Please enter a description"),
});

const EditAssignment = () => {
  const navigate = useNavigate();
  const { id, assignmentId } = useParams();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["CourseAssignments", id, assignmentId],
    queryFn: () => GetSingleAssignment(id, assignmentId),
  });
  const { mutate } = useMutation({
    mutationFn: (data) => EditAssignmentAPI(id, assignmentId, data),
    onSuccess: () => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Course Lesson Added Successfully",
      });
      queryClient.invalidateQueries({ queryKey: ["CourseAssignments"] });
      navigate(`/all-courses/${id}`);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });

  if (
    isError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        {" "}
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }
  if (isLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            class="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  const initialValues = {
    title: data.title || "",
    openDate: data?.openDate ? new Date(data.openDate) : null,
    deadline: data?.deadline ? new Date(data.deadline) : null,

    description: data.description || "",
  };
  return (
    <>
      <PageTitle
        title={"Coures Assignments"}
        activeMenu={"All Courses"}
        motherMenu={"Courses"}
        userId={id}
        secondMenu={"Edit Lesson"}
        goBack={true}
        goTo={`/all-courses/${id}/Assignments`}
      />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Lesson Details</h4>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={lessonSchema}
              // validateOnBlur={true}
              // validateOnChange={true}
              onSubmit={(values, actions) => {
                const changedValues = Object.keys(values).reduce((acc, key) => {
                  if (values[key] !== initialValues[key]) {
                    acc[key] = values[key]; // Include only changed values
                  }
                  return acc;
                }, {});
                if (Object.keys(changedValues).length === 0) {
                  // If no fields were changed, do not submit and possibly alert the user
                  Swal.fire({
                    icon: "info",
                    title: "No Changes Detected",
                    text: "No changes were made to the form.",
                  });
                  actions.setSubmitting(false);
                  return; // Stop the onSubmit processing here
                }
                mutate(values, {
                  onSuccess: () => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                  },
                  onError: () => {
                    actions.setSubmitting(false);
                  },
                });
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldTouched,
                setFieldValue,
                validateField,
              }) => (
                <div className="card-body">
                  {console.log(errors)}

                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="title">
                            Title
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            id="title"
                            placeholder="Enter Coures Title"
                            type="text"
                            name="title"
                            className={`form-control ${
                              touched.title
                                ? errors.title
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.title && errors.title && (
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp">
                              {errors.title}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="openDate">
                            Open Date
                          </label>
                          <DatePicker
                            id="openDate"
                            placeholder="Select Open Time"
                            format="yyyy-MM-dd HH:mm:ss" // You can adjust the format as needed
                            style={{
                              width: "100%",
                              borderColor:
                                touched.openDate && errors.openDate
                                  ? "red"
                                  : "initial",
                              boxShadow: "none",
                            }}
                            value={values.openDate}
                            onChange={(value) =>
                              setFieldValue("openDate", value)
                            }
                            block // This prop makes the DatePicker take the full width of its parent
                          />
                          {touched.openDate && errors.openDate && (
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}>
                              {errors.openDate}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="deadline">
                            Deadline
                          </label>
                          <DatePicker
                            id="deadline"
                            placeholder="Select Open Time"
                            format="yyyy-MM-dd HH:mm:ss" // You can adjust the format as needed
                            style={{
                              width: "100%",
                              borderColor:
                                touched.deadline && errors.deadline
                                  ? "red"
                                  : "initial",
                              boxShadow: "none",
                            }}
                            value={values.deadline}
                            onChange={(value) =>
                              setFieldValue("deadline", value)
                            }
                            block // This prop makes the DatePicker take the full width of its parent
                          />
                          {touched.deadline && errors.deadline && (
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}>
                              {errors.deadline}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="description">
                            Description <span className="text-danger">*</span>
                          </label>
                          <input
                            id="description"
                            placeholder="Enter Description"
                            type="text"
                            name="description"
                            className={`form-control ${
                              touched.description && errors.description
                                ? "is-invalid"
                                : touched.description
                                ? "is-valid"
                                : ""
                            }`}
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.description && errors.description && (
                            <div className="invalid-feedback animated fadeInUp">
                              {errors.description}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button
                          type="submit"
                          className="btn btn-primary me-1">
                          Submit
                        </button>
                        <button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-danger light">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAssignment;
