import axiosInstance from "../../services/AxiosInstance";

export async function EditStudent(data, id) {
  return axiosInstance
    .put(`teacher-api/students/${id}`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error editing student:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
