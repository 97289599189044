import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import PageTitle from "../../layouts/PageTitle";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GetSingleCourse } from "../../../API/Courses/SingleCourse/CourseOverview/GetSingleCourse";
import { SuspendCourse } from "../../../API/Courses/SingleCourse/SwitchCourseStatus";
import { queryClient } from "../../../App";
import Swal from "sweetalert2";
import { DeleteCourse } from "../../../API/Courses/DeleteCourse";
import FilterCourses from "./FilterCourses";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import { RiAddBoxFill } from "react-icons/ri";
import OverView from "./CourseParts/CourseOverView";
import CourseStudents from "./CourseParts/Students/CourseStudents";
import * as Yup from "yup";
import { Formik } from "formik";
import { AddCourseStudent } from "../../../API/Courses/SingleCourse/CourseStudnts/AddCourseStudent";
import CouresLessons from "./CourseParts/Lesssons/CouresLessons";
import CourseAssignments from "./CourseParts/Assignments/CourseAssignments";
import AllExams from "./CourseParts/Exams/AllExams";
import AllCourseRates from "./CourseParts/Rates/AllCourseRates";

const AboutCourses = () => {
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(tab || "Overview");
  const [filters, setFilters] = useState({
    Overview: {},
    Students: { search: "", date: "" },
    Lessons: { search: "", date: "" },
    Assignments: { search: "", date: "" },
    Exams: { search: "", date: "" },
    Rates: { search: "", date: "" },
  });
  const [modalCentered, setModalCentered] = useState(false);

  useEffect(() => {
    setActiveTab(tab || "Overview"); // Set active tab based on URL parameter
  }, [tab, location]);

  const {
    data: CourseOverView,
    isLoading: CourseOverViewLoading,
    isError: CourseOverViewError,
  } = useQuery({
    queryKey: ["CourseOverView", id],
    queryFn: () => GetSingleCourse(id),
  });

  const { mutate: SuspendTheCourse } = useMutation({
    mutationFn: () => SuspendCourse(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["CourseOverView"] });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: CourseOverView.active
          ? "Course Suspended Successfully"
          : "Course Access Restored Successfully",
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    },
  });

  const { mutate } = useMutation({
    mutationFn: () => DeleteCourse(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["AllCourses"] });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! ",
      });
    },
  });

  function deleteCourse(id) {
    Swal.fire({
      title: "Are you sure to you want to delete this course ?",
      text: "You will not be able to recover this course file !!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        mutate(id);
        Swal.fire(
          "Deleted!",
          `Course with id #${id} has been deleted successfully.`,
          "success"
        );
        navigate("/all-courses");
      }
    });
  }

  const { mutate: AddStudent } = useMutation({
    mutationFn: (email) => AddCourseStudent(id, email),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["CourseOverView"] });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Student Added Successfully",
      });
    },
    onError: (error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${
          error.response.data.error.endsWith("is NOT Found")
            ? "Student is not found in the system"
            : error.response.data.error
        }`,
      });
    },
  });

  const handleTabSelect = (selectedKey) => {
    // Update filters based on the selected tab
    setActiveTab(() => selectedKey);
    // Update state or perform actions based on the selected tab
    setFilters((prevFilters) => ({
      ...prevFilters,
      [selectedKey]: { search: "", date: "" },
    }));
    navigate(`/all-courses/${id}/${selectedKey}`);
  };
  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [activeTab]: newFilters,
    }));
  };

  return (
    <>
      <PageTitle
        activeMenu={"All Courses"}
        motherMenu={"Courses"}
        userId={id}
        goBack
        goTo={"/all-courses"}
      />
      <FilterCourses
        activeTab={activeTab}
        filters={filters[activeTab]}
        onFilterChange={handleFilterChange}
      />

      <div className="row">
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <Tab.Container
                        onSelect={handleTabSelect}
                        defaultActiveKey={activeTab || tab}>
                        <Nav
                          as="ul"
                          className="nav nav-tabs lg:d-flex">
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Overview">Overview</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Students">Students</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Lessons">Lessons</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Assignments">
                              Assignments
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Exams">Exams</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Rates">Rates</Nav.Link>
                          </Nav.Item>
                          <Nav className="ms-auto mt-4 mt-lg-0">
                            {(tab || activeTab) === "Overview" && (
                              <div
                                style={{ height: "2rem" }}
                                className="d-flex gap-3 align-items-center">
                                <Link to={"/all-courses/edit-course/" + id}>
                                  <Button variant="primary">
                                    <i className="fa fa-pencil" />
                                    {"  "} Edit
                                  </Button>
                                </Link>
                                <Link onClick={() => SuspendTheCourse(id)}>
                                  <Button variant="primary">
                                    {!CourseOverViewLoading &&
                                    !CourseOverViewError &&
                                    CourseOverView.active
                                      ? "Suspend"
                                      : "Activate"}
                                  </Button>
                                </Link>
                                <Link onClick={() => deleteCourse(id)}>
                                  <Button variant="danger">
                                    <i className="fa fa-trash" />
                                    {"  "}
                                    Delete
                                  </Button>
                                </Link>
                              </div>
                            )}
                            {(tab || activeTab) === "Students" && (
                              <>
                                <button
                                  onClick={() => setModalCentered(true)}
                                  className="btn sharp btn-primary px-3 ">
                                  Add Student <RiAddBoxFill size={20} />
                                </button>

                                <Modal
                                  className="fade bd-example-modal-lg"
                                  show={modalCentered}
                                  size="lg"
                                  onHide={setModalCentered}
                                  centered>
                                  <Modal.Header>
                                    <Modal.Title>Add new student</Modal.Title>
                                    <Button
                                      onClick={() => setModalCentered(false)}
                                      variant=""
                                      className="btn-close"></Button>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <Formik
                                      initialValues={{
                                        email: "",
                                      }}
                                      validationSchema={Yup.object().shape({
                                        email: Yup.string()
                                          .email("Invalid email")
                                          .required("Please provide an email"),
                                      })}
                                      // validateOnBlur={true}
                                      // validateOnChange={true}
                                      onSubmit={(values, actions) => {
                                        AddStudent(values, {
                                          onSuccess: () => {
                                            actions.resetForm();
                                            actions.setSubmitting(false);
                                            setModalCentered(false);
                                          },
                                          onError: () => {
                                            actions.setSubmitting(false);
                                          },
                                        });
                                      }}>
                                      {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                      }) => (
                                        <div className="card-body">
                                          <form onSubmit={handleSubmit}>
                                            <div className="col-sm-12">
                                              <div className="form-group">
                                                <label
                                                  className="form-label"
                                                  htmlFor="Email">
                                                  Email
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  id="Email"
                                                  placeholder="Email"
                                                  type="email"
                                                  name="email"
                                                  className={`form-control ${
                                                    touched.email
                                                      ? errors.email
                                                        ? "is-invalid"
                                                        : "is-valid"
                                                      : ""
                                                  }`}
                                                  value={values.email}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                />
                                                {touched.email &&
                                                  errors.email && (
                                                    <div
                                                      id="val-username1-error"
                                                      className="invalid-feedback animated fadeInUp">
                                                      {errors.email}
                                                    </div>
                                                  )}
                                              </div>
                                            </div>

                                            <Modal.Footer className="mt-5">
                                              <Button
                                                type="button"
                                                onClick={() =>
                                                  setModalCentered(false)
                                                }
                                                variant="danger light">
                                                Cancel
                                              </Button>
                                              <Button
                                                variant="primary"
                                                type="submit">
                                                Submit
                                              </Button>
                                            </Modal.Footer>
                                          </form>
                                        </div>
                                      )}
                                    </Formik>
                                  </Modal.Body>
                                </Modal>
                              </>
                            )}
                            {(tab || activeTab) === "Lessons" && (
                              <Link to={"/all-courses/" + id + "/add-lesson"}>
                                <Button variant="primary">
                                  Add Lesson <RiAddBoxFill size={20} />
                                </Button>
                              </Link>
                            )}
                            {(tab || activeTab) === "Assignments" && (
                              <Link
                                to={"/all-courses/" + id + "/add-assignment"}>
                                <Button variant="primary">
                                  Add Assignment <RiAddBoxFill size={20} />
                                </Button>
                              </Link>
                            )}
                            {(tab || activeTab) === "Exams" && (
                              <Link to={`/all-courses/${id}/add-exam`}>
                                <Button variant="primary">
                                  {" "}
                                  Add Exam <RiAddBoxFill size={20} />
                                </Button>
                              </Link>
                            )}
                          </Nav>
                        </Nav>

                        <Tab.Content className="tab-content">
                          <Tab.Pane eventKey={"Overview"}>
                            <OverView
                              CourseOverView={CourseOverView}
                              CourseOverViewLoading={CourseOverViewLoading}
                              CourseOverViewError={CourseOverViewError}
                            />
                          </Tab.Pane>
                        </Tab.Content>
                        <Tab.Content className="tab-content">
                          <Tab.Pane eventKey={"Students"}>
                            {(activeTab || tab) === "Students" && (
                              <CourseStudents
                                monthly={CourseOverView.monthly}
                                search={filters.Students.search}
                                enrollDate={filters.Students.date}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"Lessons"}>
                            {(activeTab || tab) === "Lessons" && (
                              <CouresLessons
                                search={filters.Lessons.search}
                                openDate={filters.Lessons.date}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"Assignments"}>
                            {(activeTab || tab) === "Assignments" && (
                              <CourseAssignments
                                search={filters.Assignments.search}
                                openDate={filters.Assignments.date}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"Exams"}>
                            {(activeTab || tab) === "Exams" && (
                              <AllExams
                                search={filters.Exams.search}
                                openDate={filters.Exams.date}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"Rates"}>
                            {(activeTab || tab) === "Rates" && (
                              <AllCourseRates
                                id={id}
                                search={filters.Rates.search}
                                rateDate={filters.Rates.date}
                              />
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCourses;
