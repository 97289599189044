import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { queryClient } from "../../../../../App";
import Swal from "sweetalert2";
import PageTitle from "../../../../layouts/PageTitle";
import FilterCourses from "../../FilterCourses";
import { Button, Nav, Tab } from "react-bootstrap";
import { RiAddBoxFill } from "react-icons/ri";
import { DeleteCouresExam } from "../../../../../API/Courses/SingleCourse/CourseExams/DeleteCouresExam";
import ExamOverview from "./ExamParts/ExamOverview";
import ExamQuestions from "./ExamParts/ExamQuestions";
import ExamSubbmitions from "./ExamParts/ExamSubbmitions";
import ExamAppeal from "./ExamParts/ExamAppeal";

const SingleExam = () => {
  const { id, examId, tab } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(tab || "Overview");
  const [filters, setFilters] = useState({
    Overview: {},
    Questions: {},
    Submissions: { search: "", date: "" },
    Appeals: { search: "", date: "" },
  });

  useEffect(() => {
    setActiveTab(tab || "Overview"); // Set active tab based on URL parameter
  }, [tab, location]);

  const { mutate } = useMutation({
    mutationFn: () => DeleteCouresExam(id, examId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["CouresExams"] });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! ",
      });
    },
  });

  function deleteCourse() {
    Swal.fire({
      title: "Are you sure to you want to delete this exam ?",
      text: "You will not be able to recover this exam file !!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        mutate();
        Swal.fire(
          "Deleted!",
          `Course with id #${id} has been deleted successfully.`,
          "success"
        );
        navigate(`/all-courses/${id}/Exams`);
      }
    });
  }

  const handleTabSelect = (selectedKey) => {
    // Update filters based on the selected tab
    setActiveTab(() => selectedKey);
    // Update state or perform actions based on the selected tab
    setFilters((prevFilters) => ({
      ...prevFilters,
      [selectedKey]: { search: "", date: "" },
    }));
    navigate(`/all-courses/${id}/Exams/${examId}/${selectedKey}`);
  };
  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [activeTab]: newFilters,
    }));
  };
  console.log(tab, location);

  return (
    <>
      <PageTitle
        title={"All Exams"}
        activeMenu={"All Courses"}
        motherMenu={"Courses"}
        userId={id}
        secondId={examId}
        secondMenu={"Exams"}
        goBack
        goTo={`/all-courses/${id}/Exams`}
      />
      <FilterCourses
        activeTab={activeTab}
        filters={filters[activeTab]}
        onFilterChange={handleFilterChange}
      />

      <div className="row">
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <Tab.Container
                        onSelect={handleTabSelect}
                        defaultActiveKey={activeTab || tab}>
                        <Nav
                          as="ul"
                          className="nav nav-tabs lg:d-flex w-100">
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Overview">Overview</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Questions">Questions</Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Submissions">
                              Submissions
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li">
                            <Nav.Link eventKey="Appeals">Appeals</Nav.Link>
                          </Nav.Item>
                          <Nav className="ms-auto mt-4 mt-lg-0">
                            {(tab || activeTab) === "Overview" && (
                              <div className="d-flex gap-3 align-items-center">
                                <Link
                                  to={`/all-courses/${id}/edit-exam/${examId}`}>
                                  <Button variant="primary">
                                    <i className="fa fa-pencil" />
                                    {"  "} Edit
                                  </Button>
                                </Link>

                                <Link onClick={() => deleteCourse()}>
                                  <Button variant="danger">
                                    <i className="fa fa-trash" />
                                    {"  "}
                                    Delete
                                  </Button>
                                </Link>
                              </div>
                            )}
                            {(tab || activeTab) === "Questions" && (
                              <Link
                                to={`/all-courses/${id}/Exams/${examId}/Questions/Add-question`}>
                                <Button variant="primary">
                                  Add Question <RiAddBoxFill size={20} />
                                </Button>
                              </Link>
                            )}
                            {(tab || activeTab) === "Assignments" && (
                              <Link
                                to={"/all-courses/" + id + "/add-assignment"}>
                                <Button variant="primary">
                                  Add Assignment <RiAddBoxFill size={20} />
                                </Button>
                              </Link>
                            )}
                            {(tab || activeTab) === "Exams" && (
                              <Link to={`/all-courses/${id}/add-exam`}>
                                <Button variant="primary">
                                  {" "}
                                  Add Exam <RiAddBoxFill size={20} />
                                </Button>
                              </Link>
                            )}
                          </Nav>
                        </Nav>

                        <Tab.Content className="tab-content">
                          <Tab.Pane eventKey={"Overview"}>
                            {(activeTab || tab) === "Overview" && (
                              <ExamOverview
                                id={id}
                                examId={examId}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"Questions"}>
                            {(activeTab || tab) === "Questions" && (
                              <ExamQuestions
                                id={id}
                                examId={examId}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"Submissions"}>
                            {(activeTab || tab) === "Submissions" && (
                              <ExamSubbmitions
                                search={filters.Submissions.search}
                                date={filters.Submissions.date}
                                id={id}
                                examId={examId}
                              />
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey={"Appeals"}>
                            {(activeTab || tab) === "Appeals" && (
                              <ExamAppeal
                                search={filters.Appeals.search}
                                date={filters.Appeals.date}
                                id={id}
                                examId={examId}
                              />
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleExam;
