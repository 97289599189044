import axiosInstance from "../services/AxiosInstance";

export async function ReadNotifications() {
  return axiosInstance
    .get("teacher-api/notifications/read")
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching notifications:", error);
      throw error;
    });
}
