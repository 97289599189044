const truncateString = (str, numWords) => {
  const words = str.split(" ");
  if (words.length > numWords) {
    const truncatedWords = words.slice(0, numWords);
    return truncatedWords.join(" ") + "...";
  }
  return str;
};

export default truncateString;
