import axiosInstance from "../services/AxiosInstance";

export async function CheckEmailPhoneTeacher(email = "", phone = "") {
  try {
    const params = new URLSearchParams();
    if (email) params.append("email", email);
    if (phone) params.append("phone", phone);

    const response = await axiosInstance.get(
      `/teacher-api/users/check-email-phone?${params.toString()}`
    );
    return response.data;
  } catch (error) {
    console.error("Error checking email or phone:", error);
    throw error;
  }
}
