import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import { ResponsiveContainer } from "recharts";

class DualArea extends Component {
  constructor(props) {
    super(props);

    this.data = this.props?.isIncome
      ? {
          defaultFontFamily: "Poppins",
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Income",
              data: this.props.data[0],
              borderColor: "#FF1616",
              borderWidth: "2",
              backgroundColor: "rgba(255,97,117, 0.1)",
              tension: 0.4,
              fill: false,
            },
          ],
        }
      : {
          defaultFontFamily: "Poppins",
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "Community",
              data: this.props.data[0],
              borderColor: "#1367C8",
              borderWidth: "2",
              backgroundColor: "rgba(106,115,250,0.2)",
              tension: 0.4,
              fill: false,
            },

            {
              label: "Student",
              data: this.props.data[1],
              borderColor: "#673bb7",
              borderWidth: "2",
              backgroundColor: "rgba(106,115,250,0.2)",
              tension: 0.4,
              fill: false,
            },
          ],
        };

    this.options = this.props?.isIncome
      ? {
          plugins: {
            legend: {
              display: true,
              position: "top",
              labels: {
                usePointStyle: true,
                padding: 40,
              },
            },
          },
          scales: {
            y: {
              max: Math.max(...this.props?.data[0]) + 1000,
              min: 0,
              ticks: {
                beginAtZero: true,
                color: "#000",
                stepSize: 20,
                padding: 10,
                font: {
                  size: 12,
                  weight: "600",
                },
              },
            },

            x: {
              ticks: {
                color: "#000",
                padding: 5,
                font: {
                  size: 12,
                  weight: "600",
                },
              },
            },
          },
        }
      : {
          plugins: {
            legend: {
              display: true,
              position: "top",
              labels: {
                usePointStyle: true,
                padding: 40,
              },
            },
          },
          scales: {
            y: {
              max:
                Math.max(...this.props?.data[0], ...this.props?.data[1]) + 100,
              min: 0,
              ticks: {
                beginAtZero: true,
                color: "#000",
                stepSize: 20,
                padding: 10,
                font: {
                  size: 12,
                  weight: "600",
                },
              },
            },

            x: {
              ticks: {
                color: "#000",
                padding: 5,
                font: {
                  size: 12,
                  weight: "600",
                },
              },
            },
          },
        };
  }
  render() {
    return (
      <ResponsiveContainer
        width="100%"
        height="400px">
        <Line
          data={this.data}
          options={this.options}
          height={100}
        />
      </ResponsiveContainer>
    );
  }
}

export default DualArea;
