import axiosInstance from "../../../../services/AxiosInstance";

export async function GetSingleAssignment(courseId, assignmentId) {
  let url = `teacher-api/courses/${courseId}/assignments/${assignmentId}`;
  return axiosInstance
    .get(url)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error fetching course assignments:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
