import axiosInstance from "../../services/AxiosInstance";

export async function GetTeacherCourses() {
  let url = "teacher-api/coursesIds";
  return axiosInstance
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching teacher's courses:", error);
      throw error;
    });
}
