import axiosInstance from "../../../../services/AxiosInstance";

export async function EditCourseLessonsFileTitle(
  courseId,
  lessonId,
  fileId,
  title
) {
  console.log(courseId, lessonId, fileId, title);
  let url = `teacher-api/courses/${courseId}/lessons/${lessonId}/files/${fileId}/title`;
  return axiosInstance
    .put(url, { newTitle: title })
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error editing course lesson title:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
