import {
  S3Client,
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import { TokenDecode } from "./TokenDecode";

// Create an S3 client instance
const s3Client = new S3Client({
  region: "fra1", // Your DigitalOcean Spaces region
  endpoint: "https://fra1.digitaloceanspaces.com", // DigitalOcean Spaces endpoint
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Access key from environment variables
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, // Secret key from environment variables
  },
  forcePathStyle: true,
});

export async function UploadToS3(
  imageName,
  imageFile,
  path,
  Public,
  onProgress
) {
  const bucketName = process.env.REACT_APP_S3_BUCKET;
  const fullPath = path + imageName;

  try {
    // Step 1: Initialize the multipart upload
    const createUploadCommand = new CreateMultipartUploadCommand({
      Bucket: bucketName,
      Key: fullPath,
      ContentType: imageFile.type,
      ACL: Public ? "public-read" : "private", // Access control
    });

    const createUploadResponse = await s3Client.send(createUploadCommand);
    const uploadId = createUploadResponse.UploadId; // Retrieve uploadId for multipart upload
    const partSize = 5 * 1024 * 1024; // 5MB per part
    const totalParts = Math.ceil(imageFile.size / partSize); // Calculate number of parts
    const partsArray = [];

    console.log("Starting upload for file:", fullPath);

    // Step 2: Upload each part in a loop
    for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
      const start = (partNumber - 1) * partSize;
      const end = partNumber * partSize;
      const blobPart = imageFile.slice(start, end);

      console.log(`Uploading part ${partNumber} of ${totalParts}`);

      // Upload each part
      const uploadPartCommand = new UploadPartCommand({
        Bucket: bucketName,
        Key: fullPath,
        UploadId: uploadId,
        PartNumber: partNumber,
        Body: blobPart,
      });

      // Full response logging
      const uploadPartResponse = await s3Client.send(uploadPartCommand);
      console.log(`Full response for part ${partNumber}:`, uploadPartResponse);

      // Check if ETag is present in the response
      if (uploadPartResponse.ETag) {
        console.log(`ETag for part ${partNumber}: ${uploadPartResponse.ETag}`);
        // Track part ETag and PartNumber for completing the upload
        partsArray.push({
          PartNumber: partNumber,
          ETag: uploadPartResponse.ETag, // Unique identifier for the uploaded part
        });
      } else {
        console.error(
          `Missing ETag for part ${partNumber}`,
          uploadPartResponse
        );
        throw new Error(`ETag is undefined for part ${partNumber}`);
      }

      // Update progress and trigger the onProgress callback if provided
      const percentUploaded = Math.round((partNumber / totalParts) * 100);
      console.log(`Progress: ${percentUploaded}%`);
      if (onProgress) {
        onProgress(percentUploaded);
      }
    }

    console.log("All parts uploaded. Completing upload...");

    // Step 3: Complete the multipart upload
    const completeUploadCommand = new CompleteMultipartUploadCommand({
      Bucket: bucketName,
      Key: fullPath,
      UploadId: uploadId,
      MultipartUpload: { Parts: partsArray }, // Provide the part details
    });

    const completeUploadResponse = await s3Client.send(completeUploadCommand);

    console.log("File uploaded successfully:", completeUploadResponse);
    // Return the final file URL after upload completion
    return `${process.env.REACT_APP_S3_ENDPOINT}/${bucketName}/${fullPath}`;
  } catch (err) {
    console.error("Error during multipart upload:", err);
    throw err; // Rethrow the error to be handled by the caller
  }
}

// Decode the token to retrieve community ID
const token = localStorage.getItem("token");
const decodedToken = TokenDecode(token);

// If decodedToken is null, handle the error gracefully
if (!decodedToken) {
  console.error("Token is missing or invalid");
  // Handle this error (e.g., redirect user to login)
}

// Retrieve the community ID from the decoded token
const communityId = decodedToken ? decodedToken["community-id"] : null;

// Define common paths for file uploads
export const COURSEIMG = `https://lmst-storage.fra1.digitaloceanspaces.com/Teacher/${communityId}/CourseImg/`;
export const PROFILEIMG = `https://lmst-storage.fra1.digitaloceanspaces.com/Teacher/${communityId}/ProfileImg/`;
export const PROFILE = `https://lmst-storage.fra1.digitaloceanspaces.com/Teacher/${communityId}/Profile/`;
export const COURSEFILES = `https://lmst-storage.fra1.digitaloceanspaces.com/Teacher/${communityId}/CourseFiles/`;
export const COURSEVIDEO = `https://lmst-storage.fra1.digitaloceanspaces.com/Teacher/${communityId}/CourseVideos/`;
