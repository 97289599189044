import { Link } from "react-router-dom";

function PageTitleButton({ activeMenu, buttonText, whereTo, data }) {
  return (
    <div className="row page-titles mx-0 d-flex align-items-center">
      {activeMenu && (
        <div className="col-sm-6 p-md-0">
          <div className="welcome-text d-flex align-items-center gap-1">
            <h4>{activeMenu}</h4>
          </div>
        </div>
      )}
      <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <Link
          to={whereTo}
          state={data ? data : null}>
          <button className="btn btn-primary">{buttonText}</button>
        </Link>
      </div>
    </div>
  );
}

export default PageTitleButton;
