import { useQuery } from "@tanstack/react-query";
import { GetStudentExams } from "../../../../API/Students/SingleSutdent/GetStudentExams";
import { Button, Dropdown, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Exams({ id, title, startDate }) {
  const [filteredExams, setFilteredExams] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const theadData = [
    { heading: "ID." },
    { heading: "Exam Title" },
    { heading: "Date" },
    { heading: "Score" },
    { heading: "Feedback" },
    { heading: "Exam Page" },
  ];

  const {
    data: Exams,
    isLoading: isLoadingExams,
    isError: isErrorExams,
    error,
  } = useQuery({
    queryKey: ["Exams", id],
    queryFn: () => GetStudentExams(id),
  });

  useEffect(() => {
    if (Exams) {
      const filtered = Exams.filter((exam) => {
        const matchesTitle = title
          ? exam.id.toString().includes(title) ||
            exam.title.toLowerCase().includes(title)
          : true;
        const matchesDate = startDate
          ? new Date(exam.date).toDateString() ===
            new Date(startDate).toDateString()
          : true;
        return matchesTitle && matchesDate;
      });
      setFilteredExams(filtered);
      setPageNo(0); // Reset to the first page whenever search term changes
    }
  }, [Exams, title, startDate]);

  const totalPages = Math.ceil(filteredExams.length / itemsPerPage);

  const paginatedExams = filteredExams.slice(
    pageNo * itemsPerPage,
    (pageNo + 1) * itemsPerPage
  );

  if (isLoadingExams) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  if (
    isErrorExams &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }

  return filteredExams.length === 0 ? (
    <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
      <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
        No data found
      </div>
    </div>
  ) : (
    <div className="card-body">
      <div className="table-responsive">
        <div
          id="holidayList"
          className="dataTables_wrapper no-footer">
          <div className="justify-content-between d-sm-flex">
            <div className="dataTables_length">
              <label className="d-flex align-items-center">
                Show
                <Dropdown className="search-drop">
                  <Dropdown.Toggle
                    as="div"
                    className="search-drop-btn">
                    {itemsPerPage}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setItemsPerPage(10)}>
                      10
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(20)}>
                      20
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(30)}>
                      30
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                entries
              </label>
            </div>
          </div>
          <table
            id="example4"
            className="display dataTable no-footer w-100">
            <thead>
              <tr>
                {theadData.map((item, ind) => (
                  <th key={ind}>{item.heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedExams.map((data, ind) => (
                <tr key={data.id}>
                  <td>
                    <strong>{data.id}</strong>
                  </td>
                  <td>{data.title}</td>
                  <td>{new Date(data.date).toDateString()}</td>
                  <td>
                    {data.score || 0}%{" "}
                    {/* <Button
                      onClick={() => handleOpenModal(data.id)}
                      className="ms-2"
                      variant="outline-primary">
                      <i className="fa fa-pencil" />
                      {"  "}Primary
                    </Button>
                    {changeScore === data.id && (
                      <Modal
                        className="fade"
                        show={changeScore === data.id}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={handleCloseModal}>
                        <Modal.Header>
                          <Modal.Title>Modal title</Modal.Title>
                          <Button
                            variant="close"
                            className="btn-close"
                            onClick={handleCloseModal}></Button>
                        </Modal.Header>
                        <Modal.Body>Modal body text goes here.</Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger light"
                            onClick={handleCloseModal}>
                            Close
                          </Button>
                          <Button
                            variant=""
                            type="button"
                            className="btn btn-primary">
                            Save changes
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )} */}
                  </td>
                  <td>
                    <Link
                      to={
                        "/all-courses/" +
                        data.courseId +
                        "/Exams/" +
                        data.id +
                        "/Submissions/" +
                        data.feedbackId +
                        "/feedback"
                      }>
                      <Button variant="primary d-flex justify-content-center align-items-center gap-2 w-75">
                        <span>Open Feedback</span>
                        <i class="fa-solid fa-caret-right" />
                      </Button>
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={
                        "/all-courses/" + data.courseId + "/exams/" + data.id
                      }>
                      <Button variant="primary d-flex justify-content-center align-items-center gap-2 w-100">
                        <span>Open Exam</span>
                        <i class="fa-solid fa-caret-right" />
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              Showing {pageNo * itemsPerPage + 1} to{" "}
              {filteredExams.length > (pageNo + 1) * itemsPerPage
                ? (pageNo + 1) * itemsPerPage
                : filteredExams.length}{" "}
              of {filteredExams.length} entries
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate">
              <Link
                className="paginate_button previous disabled"
                to="#"
                onClick={() => {
                  if (pageNo > 0) setPageNo(pageNo - 1);
                }}>
                Previous
              </Link>
              <span>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (number, i) => (
                    <Link
                      key={i}
                      to="#"
                      className={`paginate_button ${
                        pageNo === i ? "current" : ""
                      }`}
                      onClick={() => setPageNo(i)}>
                      {number}
                    </Link>
                  )
                )}
              </span>
              <Link
                className="paginate_button next"
                to="#"
                onClick={() => {
                  if (pageNo < totalPages - 1) setPageNo(pageNo + 1);
                }}>
                Next
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exams;
