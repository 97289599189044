import axiosInstance from "../../../services/AxiosInstance";

export async function GetStudentExams(id) {
  return axiosInstance
    .get(`teacher-api/students/${id}/exams`)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error fetching student's exams:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
