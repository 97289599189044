import { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DatePicker } from "rsuite";
import Select from "react-select";

function CourseFilter({ onFilterChange, educationLevels }) {
  const [open, setOpen] = useState(true);
  const [search, setSearch] = useState("");
  const [date, setDate] = useState("");
  const [educationLevel, setEducationLevel] = useState([]);
  const [courseType, setCourseType] = useState("");

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const handleEducationLevelChange = (e) => {
    setEducationLevel(e);
  };

  const handleDateChange = (value) => {
    const formattedDate = value
      ? new Date(value).toISOString().slice(0, 10)
      : "";
    setDate(formattedDate);
  };

  const resetFilters = () => {
    setSearch("");
    setDate("");
    setEducationLevel([]); // Reset the education level selection
    onFilterChange({ search: "", date: "", educationLevel: [] });
  };

  return (
    <div className="filter cm-content-box box-primary">
      <div
        className="content-title"
        onClick={() => setOpen(!open)}>
        <div className="cpa">
          <i className="fas fa-filter me-2"></i>Filter
        </div>
        <div className="tools">
          <Link
            to="#"
            className={`SlideToolHeader ${open ? "collapse" : "expand"}`}>
            <i className="fas fa-angle-up"></i>
          </Link>
        </div>
      </div>

      <Collapse in={open}>
        <div className="cm-content-body form excerpt">
          <div className="card-body">
            <div className="row filter-row">
              <div className="col-xl-3 col-sm-6">
                <label className="form-label">Search</label>
                <input
                  onChange={handleSearchChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onFilterChange({ search, date, educationLevel });
                    }
                  }}
                  value={search}
                  type="text"
                  className="form-control mb-xl-0 mb-3"
                  placeholder="Search by Course Name "
                />
              </div>
              <div className="col-xl-3 col-sm-6">
                <label className="form-label">Education Level</label>
                <Select
                  placeholder={"Education Level"}
                  defaultValue={""}
                  onChange={handleEducationLevelChange}
                  options={educationLevels}
                  value={educationLevel}
                  isMulti
                  style={{
                    lineHeight: "40px",
                    color: "#332c2c",
                    paddingLeft: " 15px",
                  }}
                />
              </div>

              <div className="col-xl-3 col-sm-6 d-flex flex-column ">
                <label className="form-label">Publish Date</label>
                <DatePicker
                  placeholder="Publish Date"
                  className="picker-suit"
                  onChange={handleDateChange}
                  value={date ? new Date(date) : null}
                  format="yyyy-MM-dd"
                />
              </div>

              <div className="col-xl-3 col-sm-6 align-self-end mt-4">
                <button
                  onClick={() =>
                    onFilterChange({ search, date, educationLevel })
                  }
                  className="btn btn-primary rounded-sm me-2"
                  title="Click here to Search"
                  type="button">
                  <i className="fa fa-search me-1" />
                  Filter
                </button>
                <button
                  onClick={resetFilters}
                  className="btn btn-danger rounded-sm light"
                  title="Click here to remove filter"
                  type="button">
                  Remove Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default CourseFilter;
