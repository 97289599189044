// admin-api/dashboard
import axiosInstance from "../services/AxiosInstance";
export async function GetCommunityEDucationLevels() {
  return axiosInstance
    .get("teacher-api/community/levels")
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching Community levels:", error);
      throw error;
    });
}
