import axiosInstance from "../../services/AxiosInstance";

export async function DeleteAnnouncements(announcemetId) {
  let url = `teacher-api/announces/${announcemetId}`;
  return axiosInstance
    .delete(url)
    .then((response) => response.data) // Return directly the data part of the response
    .catch((error) => {
      console.error("Error Deleting announcement:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
