import { useMutation, useQuery } from "@tanstack/react-query";
import { Dropdown, Row, Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { GetAllAssignmentsSubmissions } from "../../../../../../API/Courses/SingleCourse/CourseAssignments/AssignmentsSubmissions/GetAllAssignmentsSubmissions";
import { IMAGES } from "../../../../../constant/theme";
import { COURSEFILES, UploadToS3 } from "../../../../../../util/UploadToS3";
import { EditSubmissionFeedback } from "../../../../../../API/Courses/SingleCourse/CourseAssignments/AssignmentsSubmissions/EditSubmissionFeedback";
import { queryClient } from "../../../../../../App";
import Swal from "sweetalert2";
import { DeleteSubmissionFeedback } from "../../../../../../API/Courses/SingleCourse/CourseAssignments/AssignmentsSubmissions/DeleteSubmissionFeedback";
import { AddNewSubmissionFeedback } from "../../../../../../API/Courses/SingleCourse/CourseAssignments/AssignmentsSubmissions/AddNewSubmissionFeedback";

const loginSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .min(3, "Title must be at least 3 characters")
    .matches(
      /^(?=.*[a-zA-Z\u0600-\u06FF])[a-zA-Z\u0600-\u06FF0-9 _-]*$/,
      "Title must contain letters and can include numbers, underscores, dashes, and spaces, but cannot contain special characters like @#$%"
    )
    .notOneOf([/^\d+$/], "Title cannot consist of numbers only"),
  score: Yup.number()
    .required("Score is required")
    .min(0, "Score must be at least 0")
    .max(100, "Score must be at most 100")
    .typeError("Score must be a number"),
  file: Yup.mixed().when("isEditing", {
    is: false, // When adding feedback
    then: (schema) =>
      schema
        .required("A file is required")
        .test(
          "fileSize",
          "The file is too large",
          (value) => value && value.size <= 1024 * 1024 * 5
        ) // 5 MB limit
        .test(
          "fileFormat",
          "Unsupported Format",
          (value) =>
            value &&
            ["application/msword", "application/pdf"].includes(value.type)
        ),
    otherwise: (schema) => schema.notRequired(), // When editing feedback
  }),
});

function AssignmentsSubmissions({ id, submissionId, title, startDate }) {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [editFeedback, setEditFeedback] = useState(false);
  const { feedbackId } = useParams();
  const navigate = useNavigate();

  const theadData = [
    { heading: "ID." },
    { heading: "Feedback Title" },
    { heading: "Student" },
    { heading: "Upload Date" },
    { heading: "Score" },
    { heading: "Evaluated" },
    { heading: "Action" },
  ];

  const {
    data: SubmissionsData,
    isLoading: SubmissionsLoading,
    isError: SubmissionsError,
    error,
  } = useQuery({
    queryKey: ["SingleStudentSubmissions", id, submissionId],
    queryFn: () => GetAllAssignmentsSubmissions(id, submissionId),
  });

  const { mutate: editFeedbackMutation } = useMutation({
    mutationFn: (data) =>
      EditSubmissionFeedback(id, submissionId, feedbackId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["SingleStudentSubmissions"] });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Feedback Edited Successfully",
      });

      setEditFeedback(false);
    },
  });
  const { mutate: addFeedbackMutation } = useMutation({
    mutationFn: (data) =>
      AddNewSubmissionFeedback(id, submissionId, feedbackId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["SingleStudentSubmissions"] });

      setEditFeedback(false);
    },
  });

  const { mutate: deleteFeedbackMutation } = useMutation({
    mutationFn: (feedbackId) =>
      DeleteSubmissionFeedback(id, submissionId, feedbackId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["SingleStudentSubmissions"] });
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Feedback Deleted Successfully",
      });

      setEditFeedback(false);
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Oops...!",
        text: "Something went wrong!",
      });
    },
  });

  function deleteFeedback(id) {
    Swal.fire({
      title: "Are you sure to you want to delete this feedback ?",
      text: "You will not be able to recover this course file !!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFeedbackMutation(id);
      }
    });
  }

  const handleOpenFeedbackModal = (feedbackId) => {
    setEditFeedback(true);
    navigate(
      `/all-courses/${id}/submissions/${submissionId}/feedback/${feedbackId}`
    );
  };

  // Close modal and revert URL
  const handleCloseFeedbackModal = () => {
    setEditFeedback(false);
    navigate(`/all-courses/${id}/submissions/${submissionId}`);
  };

  useEffect(() => {
    if (SubmissionsData) {
      const filtered = SubmissionsData.filter((submission) =>
        title
          ? submission.id.toString().includes(title) ||
            submission.studentName.toLowerCase().includes(title)
          : true
      ).filter((submission) =>
        startDate
          ? new Date(submission.uploadTime).toISOString().slice(0, 10) ===
            startDate
          : true
      );
      setFilteredSubmissions(filtered);
      setPageNo(0); // Reset to the first page whenever filter changes
    }
  }, [SubmissionsData, title, startDate]);

  const totalPages = Math.ceil(filteredSubmissions.length / itemsPerPage);

  const paginatedSubmissions = filteredSubmissions.slice(
    pageNo * itemsPerPage,
    (pageNo + 1) * itemsPerPage
  );

  useEffect(() => {
    if (feedbackId) {
      handleOpenFeedbackModal(feedbackId);
    }
  }, [feedbackId]);

  if (
    SubmissionsError &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }
  if (SubmissionsLoading) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  return filteredSubmissions.length === 0 ? (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
                No data found
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="row">
      <div className=" col-lg-12">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <div
                    id="holidayList"
                    className="dataTables_wrapper no-footer">
                    <div className="justify-content-between d-sm-flex">
                      <div className="dataTables_length">
                        <label className="d-flex align-items-center">
                          Show
                          <Dropdown className="search-drop">
                            <Dropdown.Toggle
                              as="div"
                              className="search-drop-btn">
                              {itemsPerPage}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => setItemsPerPage(10)}>
                                10
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setItemsPerPage(20)}>
                                20
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setItemsPerPage(30)}>
                                30
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          entries
                        </label>
                      </div>
                    </div>
                    <table
                      id="example4"
                      className="display dataTable no-footer w-100">
                      <thead>
                        <tr>
                          {theadData.map((item, ind) => (
                            <th key={ind}>{item.heading}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedSubmissions.map((data, ind) => (
                          <tr key={ind}>
                            <td>
                              <strong>{data.id}</strong>
                            </td>
                            <td>
                              <strong>
                                {data.feedbackTitle
                                  ? data.feedbackTitle
                                  : "N/A"}
                              </strong>
                            </td>
                            <td>
                              {" "}
                              <img
                                className="rounded-circle me-2"
                                width="35"
                                src={data.studentImage}
                                alt="Person"
                                onError={(e) => {
                                  e.target.src = IMAGES.Avatpng1;
                                }}
                              />
                              {data.studentName}
                            </td>
                            <td>{new Date(data.uploadTime).toDateString()}</td>
                            <td>{data.score || 0}%</td>
                            <td>
                              {data.evaluated === true ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="#7ED321"
                                  className="bi bi-check-circle-fill"
                                  viewBox="0 0 16 16">
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="#FF1616"
                                  className="bi bi-x-circle-fill"
                                  viewBox="0 0 16 16">
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                                </svg>
                              )}
                            </td>
                            <td>
                              <Dropdown className="custom-dropdown mb-0 ms-4 cursor-pointer">
                                <Dropdown.Toggle
                                  as="div"
                                  className="i-false">
                                  <i className="fa fa-ellipsis-v fa-2x" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  align="end"
                                  className="dropdown-menu-right">
                                  <Dropdown.Item
                                    href={COURSEFILES + data.submitFile}
                                    className="text-primary">
                                    Download submission
                                  </Dropdown.Item>
                                  {data.feedbackTitle && data.evaluated ? (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleOpenFeedbackModal(data.id)
                                      }
                                      className="text-primary">
                                      Edit Feedback
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleOpenFeedbackModal(data.id)
                                      }
                                      className="text-primary">
                                      Add Feedback
                                    </Dropdown.Item>
                                  )}
                                  <Modal
                                    className="fade bd-example-modal-lg modal-dialog-centered"
                                    show={editFeedback}
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    onHide={handleCloseFeedbackModal}>
                                    <Modal.Header>
                                      <Modal.Title>
                                        {!data.evaluated
                                          ? "Add Feedback"
                                          : `Edit Feedback #${data.id}`}
                                      </Modal.Title>
                                      <Button
                                        variant=""
                                        className="btn-close"
                                        onClick={
                                          handleCloseFeedbackModal
                                        }></Button>
                                    </Modal.Header>
                                    <Modal.Body>
                                      <Formik
                                        initialValues={{
                                          score: data.evaluated
                                            ? data.score
                                            : "",
                                          file: null,
                                          title: data.evaluated
                                            ? data.feedbackTitle
                                            : "",
                                          isEditing: data.evaluated,
                                        }}
                                        validationSchema={loginSchema}
                                        // validateOnBlur={true}
                                        // validateOnChange={true}
                                        onSubmit={(values, actions) => {
                                          const {
                                            isEditing,
                                            file,
                                            ...resData
                                          } = values;

                                          data.evaluated
                                            ? editFeedbackMutation(resData, {
                                                onSuccess: (data) => {
                                                  actions.resetForm();
                                                  actions.setSubmitting(false);
                                                  navigate(
                                                    `/all-courses/${id}/submissions/${submissionId}`
                                                  );
                                                  Swal.fire({
                                                    icon: "success",
                                                    title: "Success",
                                                    text: "Feedback Edited Successfully",
                                                  });
                                                  UploadToS3(
                                                    data.feedback,
                                                    file,
                                                    "Teacher/CourseFiles/",
                                                    true
                                                  );
                                                },
                                                onError: () => {
                                                  actions.setSubmitting(false);
                                                },
                                              })
                                            : addFeedbackMutation(resData, {
                                                onSuccess: (data) => {
                                                  actions.resetForm();
                                                  actions.setSubmitting(false);
                                                  navigate(
                                                    `/all-courses/${id}/submissions/${submissionId}`
                                                  );
                                                  Swal.fire({
                                                    icon: "success",
                                                    title: "Success",
                                                    text: "Feedback Added Successfully",
                                                  });
                                                  UploadToS3(
                                                    data.feedback,
                                                    file,
                                                    "Teacher/CourseFiles/",
                                                    true
                                                  );
                                                },
                                                onError: () => {
                                                  actions.setSubmitting(false);
                                                },
                                              });
                                        }}>
                                        {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          setFieldValue,
                                        }) => (
                                          <div className="card-body">
                                            <form onSubmit={handleSubmit}>
                                              <div className="row">
                                                <div className="col-sm-6">
                                                  <div className="form-group">
                                                    <label
                                                      className="form-label"
                                                      htmlFor="title">
                                                      Title
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      id="title"
                                                      placeholder="Enter title"
                                                      type="text"
                                                      name="title"
                                                      className={`form-control ${
                                                        touched.title
                                                          ? errors.title
                                                            ? "is-invalid"
                                                            : "is-valid"
                                                          : ""
                                                      }`}
                                                      value={values.title}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                    />
                                                    {touched.title &&
                                                      errors.title && (
                                                        <div
                                                          id="val-username1-error"
                                                          className="invalid-feedback animated fadeInUp">
                                                          {errors.title}
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>

                                                <div className="col-sm-6">
                                                  <div className="form-group">
                                                    <label
                                                      className="form-label"
                                                      htmlFor="score">
                                                      Score
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      id="score"
                                                      placeholder="Enter Score"
                                                      type="text"
                                                      name="score"
                                                      className={`form-control ${
                                                        touched.score
                                                          ? errors.score
                                                            ? "is-invalid"
                                                            : "is-valid"
                                                          : ""
                                                      }`}
                                                      value={values.score}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                    />
                                                    {touched.score &&
                                                      errors.score && (
                                                        <div
                                                          id="val-username1-error"
                                                          className="invalid-feedback animated fadeInUp">
                                                          {errors.score}
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                                <div className="col-sm-6">
                                                  <div className="form-group">
                                                    <label
                                                      htmlFor="file"
                                                      className="form-label">
                                                      File
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      className={`form-control ${
                                                        touched.file
                                                          ? errors.file
                                                            ? "is-invalid"
                                                            : "is-valid"
                                                          : ""
                                                      }`}
                                                      type="file"
                                                      id="file"
                                                      name="file"
                                                      onChange={(e) => {
                                                        setFieldValue(
                                                          "file",
                                                          e.currentTarget
                                                            .files[0]
                                                        );
                                                        setFieldValue(
                                                          "feedback",
                                                          e.currentTarget
                                                            .files[0].name
                                                        );
                                                        setFieldValue(
                                                          "size",
                                                          e.currentTarget
                                                            .files[0].size /
                                                            1024
                                                        );
                                                        console.log(
                                                          e.currentTarget
                                                            .files[0]
                                                        );
                                                      }}
                                                    />
                                                    {touched.file &&
                                                      errors.file && (
                                                        <div
                                                          id="val-username1-error"
                                                          className="invalid-feedback animated fadeInUp">
                                                          {errors.file}
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                              </div>

                                              <Modal.Footer>
                                                <Button
                                                  variant="danger light"
                                                  onClick={
                                                    handleCloseFeedbackModal
                                                  }>
                                                  Close
                                                </Button>
                                                <Button
                                                  type="submit"
                                                  variant="primary">
                                                  Save changes
                                                </Button>
                                              </Modal.Footer>
                                            </form>
                                          </div>
                                        )}
                                      </Formik>
                                    </Modal.Body>
                                  </Modal>
                                  {data.feedbackTitle && data.evaluated && (
                                    <Dropdown.Item
                                      className="text-danger"
                                      onClick={() => deleteFeedback(data.id)}>
                                      Delete Feedback
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                      <div className="dataTables_info">
                        Showing {pageNo * itemsPerPage + 1} to{" "}
                        {filteredSubmissions.length >
                        (pageNo + 1) * itemsPerPage
                          ? (pageNo + 1) * itemsPerPage
                          : filteredSubmissions.length}{" "}
                        of {filteredSubmissions.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="example5_paginate">
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={() => {
                            if (pageNo > 0) setPageNo(pageNo - 1);
                          }}>
                          Previous
                        </Link>
                        <span>
                          {Array.from(
                            { length: totalPages },
                            (_, i) => i + 1
                          ).map((number, i) => (
                            <Link
                              key={i}
                              to="#"
                              className={`paginate_button ${
                                pageNo === i ? "current" : ""
                              }`}
                              onClick={() => setPageNo(i)}>
                              {number}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={() => {
                            if (pageNo < totalPages - 1) setPageNo(pageNo + 1);
                          }}>
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignmentsSubmissions;
