import axiosInstance from "../services/AxiosInstance";

export async function GetNotifications(page = 0, size = 5) {
  return axiosInstance
    .get("teacher-api/notifications", {
      params: {
        page,
        size,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching notifications:", error);
      throw error;
    });
}
