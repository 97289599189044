import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Dropdown,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GetCouresExams } from "../../../../../API/Courses/SingleCourse/CourseExams/GetCouresExams";
import { queryClient } from "../../../../../App";
import Swal from "sweetalert2";
import { DeleteCouresExam } from "../../../../../API/Courses/SingleCourse/CourseExams/DeleteCouresExam";
import truncateString from "../../../../../util/truncateString";

function AllExams({ search, openDate }) {
  const [filteredExams, setFilteredExams] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { id } = useParams();
  const navigate = useNavigate();

  const theadData = [
    { heading: "ID." },
    { heading: "Exam Title" },
    { heading: "Start Date" },
    { heading: "Deadline Date" },
    { heading: "Duration" },
    { heading: "Attempts" },
    { heading: "Actions" },
  ];

  const {
    data: Exams,
    isLoading: isLoadingExams,
    isError: isErrorExams,
    error,
  } = useQuery({
    queryKey: ["CouresExams", id],
    queryFn: () => GetCouresExams(id),
  });

  const { mutate: deleteExamMutation } = useMutation({
    mutationFn: (examId) => DeleteCouresExam(id, examId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["CouresExams"] });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to delete exam",
      });
    },
  });

  function deleteExam(id) {
    Swal.fire({
      title: "Are you sure to you want to delete this Exam ?",
      text: "You will not be able to recover it !!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#df0d0d",
      cancelButtonColor: "#aaa",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteExamMutation(id);
        Swal.fire(
          "Deleted!",
          `Exam with id #${id} has been deleted successfully.`,
          "success"
        );
      }
    });
  }
  useEffect(() => {
    if (Exams) {
      const filtered = Exams.filter((exam) => {
        const matchesTitle = search
          ? exam.examId.toString().includes(search) ||
            exam.title.toLowerCase().includes(search.toLowerCase())
          : true;
        const matchesDate = openDate
          ? new Date(exam.startDate).toDateString() ===
            new Date(openDate).toDateString()
          : true;
        return matchesTitle && matchesDate;
      });
      setFilteredExams(filtered);
      setPageNo(0); // Reset to the first page whenever search term or filter changes
    }
  }, [Exams, search, openDate]);

  const totalPages = Math.ceil(filteredExams.length / itemsPerPage);
  let paggination =
    (!isLoadingExams &&
      Array(Math.ceil(Exams.length / itemsPerPage))
        .fill()
        .map((_, i) => i + 1)) ||
    [];

  if (isLoadingExams) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          <div
            className="spinner-border"
            style={{
              width: "5rem",
              height: "5rem",
              color: "var(--primary)",
            }}
            role="status"></div>
          <span className="text-primary">Loading...</span>
        </div>
      </Row>
    );
  }

  if (
    isErrorExams &&
    error.response.data.error !== "In valid email or password" &&
    error.response.status !== 403
  ) {
    return (
      <Row>
        <div className="mt-5 d-flex justify-content-center  align-items-center gap-2">
          Something went wrong
        </div>
      </Row>
    );
  }

  return filteredExams.length === 0 ? (
    <div className="d-flex justify-content-between flex-md-row flex-column gap-xsm-1 gap-md-4 gap-3">
      <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 pt-4 ">
        No data found
      </div>
    </div>
  ) : (
    <div className="card-body">
      <div className="table-responsive">
        <div
          id="holidayList"
          className="dataTables_wrapper no-footer">
          <div className="justify-content-between d-sm-flex">
            <div className="dataTables_length">
              <label className="d-flex align-items-center">
                Show
                <Dropdown className="search-drop">
                  <Dropdown.Toggle
                    as="div"
                    className="search-drop-btn">
                    {itemsPerPage}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setItemsPerPage(10)}>
                      10
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(20)}>
                      20
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setItemsPerPage(30)}>
                      30
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                entries
              </label>
            </div>
          </div>
          <table
            id="example4"
            className="display dataTable no-footer w-100">
            <thead>
              <tr>
                {theadData.map((item, ind) => (
                  <th key={ind}>{item.heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredExams.map((data) => (
                <tr key={data.examId}>
                  <td>
                    <strong>{data.examId}</strong>
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{data.title}</Tooltip>}>
                      <span>{truncateString(data.title, 3)}</span>
                    </OverlayTrigger>
                  </td>
                  <td>{new Date(data.startDate).toDateString()}</td>
                  <td>{new Date(data.deadline).toDateString()}</td>
                  <td>
                    {data.duration !== null
                      ? `${data.duration * 60} Minutes`
                      : "Not set"}
                  </td>

                  <td>
                    {data.oneTimeAccess
                      ? "One Time Access"
                      : "Multi Time Access"}
                  </td>
                  <td className="d-flex align-items-center gap-3">
                    <Link
                      className="w-75"
                      to={`/all-courses/${id}/Exams/${data.examId}`}>
                      <Button variant="primary d-flex gap-2 align-items-center w-100 justify-content-center">
                        <span>Open Exam</span>
                        <i className="fa fa-arrow-right" />
                      </Button>
                    </Link>

                    <Button
                      variant="primary"
                      onClick={() =>
                        navigate(`/all-courses/${id}/edit-exam/${data.examId}`)
                      }
                      className="btn sharp btn-primary">
                      <i className="fa fa-pencil" />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => deleteExam(data.examId)}>
                      <i className="fa fa-trash" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              Showing {pageNo * itemsPerPage + 1} to{" "}
              {filteredExams.length > (pageNo + 1) * itemsPerPage
                ? (pageNo + 1) * itemsPerPage
                : filteredExams.length}{" "}
              of {filteredExams.length} entries
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example5_paginate">
              <Link
                className="paginate_button previous disabled"
                to="#"
                onClick={() => {
                  if (pageNo > 0) setPageNo(pageNo - 1);
                }}>
                Previous
              </Link>
              <span>
                {paggination.map((number, i) => (
                  <Link
                    key={i}
                    to="#"
                    className={`paginate_button ${
                      pageNo === i ? "current" : ""
                    }`}
                    onClick={() => setPageNo(i)}>
                    {number}
                  </Link>
                ))}
              </span>
              <Link
                className="paginate_button next"
                to="#"
                onClick={() => {
                  if (pageNo < totalPages - 1) setPageNo(pageNo + 1);
                }}>
                Next
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllExams;
