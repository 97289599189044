import axiosInstance from "../../services/AxiosInstance";

export async function GetAllStudents(
  searchTxt,
  registerDate,
  eduLevel,
  page,
  size
) {
  const params = {
    ...(searchTxt && { searchTxt }),
    ...(registerDate && { registerDate }),
    ...(eduLevel && { eduLevel }),
    page,
    size,
  };
  let url = "teacher-api/students";
  return axiosInstance
    .get(url, { params })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching students:", error);
      throw error;
    });
}
