import axiosInstance from "../../services/AxiosInstance";

export async function DeleteStudent(id) {
  return axiosInstance
    .delete(`teacher-api/students/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error deleting student:", error);
      throw error; // Rethrow to be caught by React Query error handling
    });
}
